import { PersonModel } from './person.model';
import { CompanyModel } from './company.model';
import { SettingsModel } from './settings.model';
import { StoreModel } from '../store/store.model';
import { AddressModel } from '../address/address.model';
import { DocumentModel } from '../document/document.model';
import { BankAccountModel } from '../bank-account/bank-account.model';
import { CouponFidelityModel } from '../fidelity/coupon-fidelity.model';
import { SellerStatusType } from '../../types/seller/seller-status-type.type';
import { LocationModel } from 'libs/shared/src/lib/models/utils/location.model';

export class SellersDataModel {
  public sellers: ResultListResponseSellers;
}
export class ResultListResponseSellers {
  pageSize: number;
  page: number;
  totalPages: number;
  total: number;
  results: SellerModel[];
}
export class SellerModel {
  public results?: SellerModel;
  public id?: string = '';
  public name?: string = '';
  public email: string = '';
  public cpf: string = '';
  public nickname?: string = '';
  public logo?: string = '';
  public formatedDocument?: string = '';
  public cnpj?: string = '';
  public phone: string = '';
  public occupation?: string = '';
  public location?: LocationModel = new LocationModel();
  public businessSegmentId?: string = '';
  public company?: CompanyModel = new CompanyModel();
  public person?: PersonModel = new PersonModel();
  public birthDate?: string = '';
  public status: SellerStatusType;
  public statusReason?: string = '';
  public marketplaceId?: string = '';
  public marketplace_id?: string = '';
  public imageUrl?: string = '';
  public image_url?: string = '';
  public insertedAt?: string = '';
  public updatedAt?: string = '';
  public termVersion?: string = '';
  public termAcceptedAt?: string = '';
  public settings?: SettingsModel = new SettingsModel();
  public versions?: Version[];
  public limits?: Limit[];
  public externalConsults?: ExternalConsult[];
  public bankAccounts?: BankAccountModel[];
  public seller?: SellerModel;
  public sellers?: SellerModel[];
  public emailVerified?: boolean = false;
  public phoneVerified?: boolean = false;
  public receivingLimit?: number = 0;
  public displayName?: string = '';
  public documents?: DocumentModel[] = [];
  public isStore?: boolean = false;
  public store?: StoreModel = new StoreModel();
  public stores?: StoreModel = new StoreModel();
  public observation?: string = '';
  public business_name?: string = '';
  public trade_name?: string = '';
  public externalId?: string = '';
  public userId?: string = '';
  public marketplace?: any = {};
  public sellersPaymentMethods?: SellerPaymentMethod[] = [];
  public isBnplActive?: boolean = false;
  public bnplRequestAt?: string = '';
  public platformTermAccepted?: boolean = true;
  public coupons?: Array<CouponFidelityModel> = [];
  public loading?: boolean = false;
  public address?: AddressModel = new AddressModel();
  public celcoinAccount?: CelcoinAccount;
}

export type Version = {
  event?: string;
  insertedAt?: Date;
};

export type CelcoinAccount = {
  accountNumber: string;
};

export type Limit = {
  action?: Action;
  functionality?: Functionality;
  category?: Category;
  perPeriod?: Period;
  amount?: number;
};

export type ExternalConsult = {
  id: string;
  cpfCnpj?: string;
  datasets?: string[];
  dataJson?: JSON;
  url?: string;
};

export type Action = {
  name?: string;
};

export type Functionality = {
  name?: string;
};

export type Category = {
  name?: string;
};

export type SellerPaymentMethod = {
  id: string;
  paymentMethods: { name: string; displayName: string };
  status: string;
};

export type Period = {
  name?: string;
};
