<section>
  <nz-row>
    <nz-col [nzSpan]="24" class="m-t-2 center centerBlock">
      <img src="../../../assets/icons/no-cupons.svg" alt="Sem cupons" />
    </nz-col>
    <nz-col [nzSpan]="24" class="m-t-2">
      <p>Esse Vendedor não possuí cupons disponíveis!</p>
    </nz-col>
    <nz-col [nzSpan]="24">
      <app-secondary-button routerLink="/external/home" (click)="$modalRef.close()" [block]="true"
        >Voltar
      </app-secondary-button>
    </nz-col>
  </nz-row>
</section>
