import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-no-active-cupons',
  templateUrl: './modal-no-active-cupons.component.html',
  styleUrl: './modal-no-active-cupons.component.scss',
})
export class ModalNoActiveCuponsComponent {
  constructor(public $modalRef: NzModalRef) {}
}
