<main id="lead-onboarding">
  <section>
    <nz-row>
      <nz-col nzSpan="24">
        <nz-tabset nzSize="large" (nzSelectedIndexChange)="onChangeTab($event)" [nzSelectedIndex]="selectedIndex">
          <nz-tab nzTitle="Entrar">
            <section *ngIf="showAlert">
              <div [ngClass]="typeAlert === 'success' ? 'alert alert__success' : 'alert alert__error'">
                <img
                  [src]="
                    typeAlert === 'success'
                      ? '../../../assets/icons/circle-check.svg'
                      : '../../../assets/icons/circle-exclamation.svg'
                  "
                  [alt]="typeAlert === 'success' ? 'Ícone de aprovado' : 'Ícone de reprovado'"
                />
                <span>{{ alertMessage }}</span>
              </div>
            </section>
            <form *ngIf="!setNewPhone" nz-form [formGroup]="formPhoneLogin">
              <nz-row class="m-b-1-3">
                <nz-col nzSpan="24">
                  <p class="no-margin-bottom description">
                    Se você já tem cadastro, informe o seu número de celular para entrar na plataforma de cupons.
                  </p>
                </nz-col>
              </nz-row>
              <nz-form-item class="m-t-1">
                <nz-form-label [nzNoColon]="true" nzFor="phoneNumber" class="label-text">
                  Número de celular
                </nz-form-label>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Número inválido">
                  <input
                    nz-input
                    formControlName="phoneNumber"
                    mask="(00) 0 0000-0000"
                    type="tel"
                    id="phoneNumber"
                    placeholder="(00) 0 0000-0000"
                    autocomplete="off"
                  />
                </nz-form-control>
              </nz-form-item>
              <div class="confirm-section">
                <app-primary-button
                  [loading]="loading"
                  [disabled]="formPhoneLogin.invalid || loading"
                  (click)="onSignIn()"
                >
                  Continuar
                </app-primary-button>
              </div>
            </form>
            <app-confirm-lead-phone-number
              *ngIf="setNewPhone"
              (changePhoneNumber)="changeNumber($event)"
              (finishModal)="closeModal($event)"
              (alertData)="prepareAlertData($event)"
            />
          </nz-tab>
          <nz-tab nzTitle="Cadastre-se">
            <section *ngIf="showAlert">
              <div [ngClass]="typeAlert === 'success' ? 'alert alert__success' : 'alert alert__error'">
                <img
                  [src]="
                    typeAlert === 'success'
                      ? '../../../assets/icons/circle-check.svg'
                      : '../../../assets/icons/circle-exclamation.svg'
                  "
                  [alt]="typeAlert === 'success' ? 'Ícone de aprovado' : 'Ícone de reprovado'"
                />
                <span>{{ alertMessage }}</span>
              </div>
            </section>
            <form *ngIf="!setNewPhone" nz-form [formGroup]="formCreateAccount">
              <nz-form-item class="m-t-1">
                <nz-form-label [nzNoColon]="true" nzFor="name" class="label-text"> Nome completo </nz-form-label>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Informe seu nome completo.">
                  <input
                    nz-input
                    formControlName="name"
                    type="text"
                    id="name"
                    placeholder="Como consta no RG ou na CNH"
                    autocomplete="off"
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="m-t-1">
                <nz-form-label [nzNoColon]="true" nzFor="cpf" class="label-text">CPF</nz-form-label>
                <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Campo obrigatório">
                  <input
                    nz-input
                    formControlName="cpf"
                    type="tel"
                    id="cpf"
                    mask="000.000.000-00"
                    placeholder="000.000.000-00"
                    autocomplete="off"
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="m-t-1">
                <nz-form-label [nzNoColon]="true" nzFor="phoneNumber" class="label-text"> Telefone </nz-form-label>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Telefone inválido">
                  <input
                    nz-input
                    formControlName="phoneNumber"
                    id="phoneNumber"
                    mask="(00) 0 0000-0000"
                    type="tel"
                    placeholder="(00) 0 0000-0000"
                    autocomplete="off"
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="m-t-1">
                <nz-form-label [nzNoColon]="true" nzFor="email" class="label-text">E-mail</nz-form-label>
                <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Informe um e-mail válido.">
                  <input
                    nz-input
                    formControlName="email"
                    type="email"
                    id="email"
                    placeholder="exemplo@exemplo.com"
                    autocomplete="off"
                  />
                </nz-form-control>
              </nz-form-item>

              <p class="m-t-1 info">
                Ao cadastrar suas informações, você concorda com os
                <a href="https://gen.com.br/transparencia/termosecondicoes" target="_blank">Termos e Condições</a> e
                <a href="https://gen.com.br/transparencia/privacidade" target="_blank">Política de Privacidade</a>.
              </p>

              <div class="footer">
                <app-primary-button
                  [loading]="loading"
                  [disabled]="formCreateAccount.invalid || loading"
                  (click)="createAccount()"
                >
                  Continuar
                </app-primary-button>
              </div>
            </form>
            <app-confirm-lead-phone-number
              *ngIf="setNewPhone"
              (changePhoneNumber)="changeNumber($event)"
              (finishModal)="closeModal($event)"
              (alertData)="prepareAlertData($event)"
            />
          </nz-tab>
        </nz-tabset>
      </nz-col>
    </nz-row>
  </section>
</main>
