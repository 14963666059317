export class StatusAlertModel {
  public status: boolean;
  public message: string;
  public type: string;
  public duration?: number;
}

export class AlertType {
  SUCCESS: string = 'SUCCESS';
  ERROR: string = 'ERROR';
}

export class AlertDuration {
  SOON: number = 3000;
  LONG: number = 10000;
}
