import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleDetectorService {
  private idleTimer: any;
  private idleTimeout: number = 30000;
  private isInitialScreen: boolean = false;
  private initialRoute: string = '/virtual-counter';
  private userActivity: Subject<void> = new Subject<void>();

  constructor(private router: Router, private ngZone: NgZone) {}

  public startWatching() {
    this.resetTimer();

    ['click', 'mousemove', 'keydown', 'touchstart'].forEach((event) =>
      document.addEventListener(event, () => this.userActivity.next())
    );

    this.userActivity.subscribe(() => this.resetTimer());

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.isInitialScreen = event.url === this.initialRoute;
    });
  }

  private resetTimer() {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }
    this.idleTimer = setTimeout(() => this.handleInactivity(), this.idleTimeout);
  }

  private handleInactivity() {
    if (!this.isInitialScreen) {
      this.ngZone.run(() => {
        this.router.navigate([this.initialRoute]);
      });
    }
  }
}
