<app-alert [message]="alertInfo?.message" *ngIf="alertInfo?.status"></app-alert>

<section *ngIf="!loading">
  <header>
    <img
      [ngClass]="isExpired || coupon.redeemed ? 'coupon-image inactive-image' : 'coupon-image'"
      [src]="coupon?.coupon_image || coupon?.seller?.image_url"
      onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
      alt="Imagem do cupom"
      [class.coupon-image-rescued]="coupon.rescued || coupon.redeemed"
      class="coupon-image"
    />
    @if (coupon?.sellerId !== 'cd807e12-c63a-4a2c-9109-39ca2c261d99') {
    <div class="discount-label">
      {{
        coupon.typeDiscount === 'PERCENT'
          ? coupon.value / 100 + '%'
          : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
      }}
      <br />
      OFF
    </div>
    }

    <img
      class="xmark"
      src="/assets/img/xmark.svg"
      alt="Ícone ilustrando um x para fechar o modal"
      (click)="modalRef.close()"
    />
  </header>

  <body>
    <div class="title-container">
      <img
        class="logo-coupon"
        [src]="coupon?.seller?.image_url"
        onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
        alt="Imagem do cupom"
      />

      <div class="empty"></div>

      <div class="title">
        <h1>
          {{ coupon?.seller?.business_name || seller?.business_name }}
          {{ coupon?.mall?.name }}
        </h1>

        <p [innerHTML]="coupon?.description"></p>
      </div>
    </div>

    <div class="choose-option-container">
      <a
        [ngClass]="isUseCouponOption ? 'choose-option choose-option__active' : 'choose-option'"
        (click)="chooseOption('USE')"
        >Usar cupom</a
      >
      <a
        [ngClass]="!isUseCouponOption ? 'choose-option choose-option__active' : 'choose-option'"
        (click)="chooseOption('DETAILS')"
        >Detalhes do cupom</a
      >
    </div>

    <div class="use-coupon" *ngIf="isUseCouponOption">
      <div class="coupon-card-container">
        <nz-card class="coupon-card pointer-cursor">
          <nz-row [nzGutter]="{ xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }" class="image-bottom">
            <nz-col nzSpan="11">
              <span class="effect-coupoun-left"></span>
              <span class="effect-coupoun-left effect-coupoun-left__ghost"></span>

              <div class="coupon-data">
                @if (isMobile) {
                <h3 class="coupon-title">
                  {{ coupon?.seller?.business_name || seller?.business_name }}
                  {{
                    coupon?.seller?.business_name?.length > 20 ||
                    seller?.business_name?.length > 20 ||
                    coupon.description?.length > 35
                      ? coupon?.mall?.name?.substring(0, 10) + '...'
                      : coupon?.mall?.name
                  }}
                </h3>
                } @else {
                <h3 class="coupon-title">
                  @if (coupon?.seller?.business_name?.length > 20 || seller?.business_name?.length > 20 ||
                  coupon.description.length > 35) {
                  {{ coupon?.seller?.business_name?.substring(0, 15) || seller?.business_name?.substring(0, 15) }}
                  {{ coupon?.mall?.name?.substring(0, 5) + '...' }}

                  } @else if (coupon?.mall?.name?.length > 18) {
                  {{ coupon?.seller?.business_name?.substring(0, 15) || seller?.business_name?.substring(0, 15) }}
                  {{ coupon?.mall?.name?.substring(0, 5) + '...' }}

                  } @else {
                  {{ coupon?.seller?.business_name || seller?.business_name }}
                  {{ coupon?.mall?.name }} }
                </h3>
                }

                <div class="effect-group-cupon-middle">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <button class="fav-button" (click)="likeCoupon(coupon); $event.stopPropagation()">
                  <img
                    [src]="coupon.isFavorite ? '/assets/img/red-heart-coupon.svg' : '/assets/img/heart-coupon.svg'"
                    alt="Icone de coração"
                    class="icon-img"
                  />
                </button>

                <p class="coupon-description">
                  @if (coupon.description && coupon.description.length > 35) {
                  <span [innerHTML]="coupon.description.substring(0, 35) + '...'"> </span>
                  } @else {
                  <span [innerHTML]="coupon?.description || ''"> </span>
                  }
                </p>

                @if (coupon?.redeemed) {
                <div class="coupon-redeemed">
                  <span>{{ coupon.redeemed }} + já utilizam</span>
                </div>
                }

                <div class="d-flex">
                  <h3 class="coupon-rating no-margin">{{ coupon.couponRating }} / 5</h3>
                  <img class="p-l-1" src="../../assets/icons/star.svg" alt="Imagem de estrela" />
                </div>
              </div>
            </nz-col>

            <nz-col nzSpan="13" class="coupon-image">
              <nz-row class="w-100">
                <nz-col [nzSpan]="13">
                  <img
                    class="coupon-image"
                    [src]="coupon?.coupon_image || coupon?.seller?.image_url"
                    onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                    alt="Imagem do cupom"
                  />
                </nz-col>
                <nz-col [nzSpan]="11">
                  <img
                    class="logo-coupon"
                    [src]="coupon?.seller?.image_url"
                    onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                    alt="Imagem do cupom"
                  />
                  @if(coupon?.sellerId === 'cd807e12-c63a-4a2c-9109-39ca2c261d99'){
                  <div
                    class="discount-label gift"
                    [ngClass]="{
                      'discount-label-active': reference === 'ACTIVE',
                      'discount-label-inactive': reference === 'INACTIVE',
                      'discount-label-default': reference !== 'ACTIVE' && reference !== 'INACTIVE'
                    }"
                  >
                    <img src="../../assets/icons/gift.svg" alt="Icone de presente" />
                  </div>

                  } @else {
                  <div
                    class="discount-label"
                    [ngClass]="{
                      'discount-label-active': reference === 'ACTIVE',
                      'discount-label-inactive': reference === 'INACTIVE',
                      'discount-label-default': reference !== 'ACTIVE' && reference !== 'INACTIVE'
                    }"
                  >
                    {{
                      coupon.typeDiscount === 'PERCENT'
                        ? coupon.value / 100 + '%'
                        : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                    }}
                    <br />
                    OFF
                  </div>
                  }
                </nz-col>
              </nz-row>
            </nz-col>
          </nz-row>
        </nz-card>
      </div>

      <gen-monorepo-shared-coupon [id]="id"></gen-monorepo-shared-coupon>

      <div class="use-coupon-button">
        <app-primary-button
          [block]="true"
          type="GREEN"
          (click)="rescueCoupon()"
          nz-tooltip
          [nzTooltipTitle]="alreadyRescued ? 'Cupom já resgatado' : ''"
          nzTooltipColor="#6051e0"
          [disabled]="alreadyRescued"
        >
          Eu quero usar esse cupom</app-primary-button
        >
      </div>
    </div>

    <div class="coupon-details-container" *ngIf="!isUseCouponOption">
      <nz-row [nzGutter]="32">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <div class="details-flex">
            <div class="details">
              <p class="coupon-description" [innerHTML]="coupon.description"></p>
            </div>

            <div class="inverte">
              <div class="value-off">
                <p>
                  @if (coupon?.sellerId == 'cd807e12-c63a-4a2c-9109-39ca2c261d99') { Nas compras acima de }
                  {{
                    coupon.typeDiscount === 'PERCENT'
                      ? coupon.value / 100 + '%'
                      : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                  }}
                  @if (coupon?.sellerId !== 'cd807e12-c63a-4a2c-9109-39ca2c261d99') { OFF }
                </p>
              </div>

              <div class="rules-container">
                <h2>Regras de uso</h2>

                <div class="rules-content">
                  <div class="rules" *ngIf="coupon?.expiration_date">
                    <img src="/assets/img/calendar.svg" alt="Ícone de calendário" />
                    <p>Cupom válido até {{ coupon?.expiration_date | date : 'dd/MM/yyyy' }}</p>
                  </div>

                  <div class="rules" *ngIf="coupon?.user_usage_limit">
                    <img src="/assets/img/document.svg" alt="Ícone de identidade" />
                    <p>Válido para apenas {{ coupon?.user_usage_limit }} compra por CPF</p>
                  </div>

                  <div class="rules" *ngIf="coupon?.buy_value_min && coupon?.value">
                    <img src="/assets/img/tag.svg" alt="Ícone de etiqueta" />
                    <p>
                      Para compras acima de {{ coupon?.buy_value_min }}; Desconto de até
                      {{
                        coupon.typeDiscount === 'PERCENT'
                          ? coupon.value / 100 + '%'
                          : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                      }}
                    </p>
                  </div>

                  <div class="rules">
                    <img src="/assets/img/warnning.svg" alt="Ícone de aviso" />
                    <p>Cupom sujeito a disponibilidade na loja</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-col>

        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <div class="rating-container">
            <nz-row [nzGutter]="32">
              <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="24">
                <div class="rating-content" *ngIf="isMobile">
                  <header>
                    <h3>Avaliação</h3>
                  </header>

                  <body>
                    <img src="../../assets/icons/star.svg" alt="Ícone de uma estrela " />
                    <p>{{ coupon?.couponRating }} / 5</p>
                    <span>{{ coupon?.totalAvaliations }} avaliações</span>
                  </body>

                  <footer (click)="goToAvaliations()">
                    <p>Avaliar cupom</p>
                  </footer>
                </div>

                <div class="rating-content" *ngIf="!isMobile">
                  <header>
                    <h3>Avaliação média</h3>
                  </header>

                  <body>
                    <p>{{ coupon?.couponRating }}</p>
                    <nz-rate [ngModel]="coupon.couponRating" nzAllowHalf nzDisabled></nz-rate>
                    <span>{{ coupon?.totalAvaliations }} avaliações</span>
                  </body>

                  <footer (click)="goToAvaliations()">
                    <p>Avaliar cupom</p>
                  </footer>
                </div>
              </nz-col>

              <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="24">
                <div class="classification">
                  <header>
                    <h3>Classificação</h3>
                  </header>

                  <body>
                    <div class="progress" *ngFor="let rating of ratingList">
                      <div class="number">
                        <img src="../../assets/icons/star.svg" alt="Ícone de uma estrela " />
                        <span>{{ rating.message }}</span>
                      </div>
                      <nz-progress
                        [nzPercent]="
                          rating.message === '5'
                            ? five
                            : '' || rating.message === '4'
                            ? four
                            : '' || rating.message === '3'
                            ? three
                            : '' || rating.message === '2'
                            ? two
                            : '' || rating.message === '1'
                            ? one
                            : ''
                        "
                        nzSize="small"
                        [nzShowInfo]="false"
                      ></nz-progress>
                      <span>{{ rating.rating }}</span>
                    </div>
                  </body>
                </div>
              </nz-col>
            </nz-row>
          </div>
        </nz-col>
      </nz-row>
    </div>
  </body>
</section>

<div class="center m-t-2 m-b-2 p-b-2 p-t-2 loading-container" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>
