import { Component, Input } from '@angular/core';
import { LeadService } from '../../services/lead/lead.service';
import { ErrorService } from '../../services/error/error.service';
import { AlertService } from '../../services/alert/alert.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gen-monorepo-shared-coupon',
  templateUrl: './shared-coupon.component.html',
  styleUrl: './shared-coupon.component.scss',
})
export class SharedCouponComponent {
  @Input() public id: string = '';

  public methods: Array<{ imageUrl: string; type: string }> = [
    { imageUrl: '/assets/img/circle-whatsapp.svg', type: 'WHATSAPP' },
    { imageUrl: '/assets/img/circle-email.svg', type: 'EMAIL' },
    { imageUrl: '/assets/img/circle-telegram.svg', type: 'TELEGRAM' },
  ];

  public url: string = '';

  public isCopied: boolean = false;
  public isCollapseClicked: boolean = false;
  public isMobile: boolean = window.innerWidth < 1200;

  public data: any = {};

  private destroy$ = new Subject();

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private readonly clipboard: Clipboard,
    private readonly $alert: AlertService
  ) {}

  public ngOnInit(): void {
    this.getUrl();
  }

  private getUrl(): void {
    this.$lead
      .shareCoupon(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.shareCoupon) {
            this.data = JSON.parse(JSON.stringify(res.data.shareCoupon));
            this.url = this.data?.links?.publicLink;
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, 'Erro ao compartilhar o cupom. Por favor, tente novamente mais tarde.');
        },
      });
  }

  public copyContent(): void {
    this.clipboard.copy(this.url);
    this.$alert.setAlertInfo('SUCCESS', 'Link de pagamento copiado com sucesso!');
    this.isCopied = true;

    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  public collapseClick(): void {
    this.isCollapseClicked = !this.isCollapseClicked;
  }

  public sendToPlatform(type: string): void {
    const links = {
      WHATSAPP: this.data?.links?.whatsapp,
      EMAIL: this.data?.links?.email,
      TELEGRAM: this.data?.links?.telegram,
    };

    window.open(links[type], '_blank');
  }

  public trackByFn(index: number, method: { imageUrl: string; type: string }): string {
    return method.type;
  }
}
