import { Component, HostListener, Input } from '@angular/core';
import { CouponFidelityModel } from '../../../models/fidelity/coupon-fidelity.model';
import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { LeadModel } from '../../../models/lead/lead.model';
import { Subject, mergeMap, of, takeUntil } from 'rxjs';
import { CouponAvaliationsFidelityModel } from '../../../models/fidelity/coupon-avaliations-fidelity.model';
import { ModalSuccessRedeemCouponComponent } from '../modal-success-redeem-coupon/modal-success-redeem-coupon.component';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { Message } from '../../../utils/message';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalLeadOnboardingComponent } from '../modal-lead-onboarding/modal-lead-onboarding.component';
import { ModalUseCouponComponent } from '../modal-use-coupon/modal-use-coupon.component';
import { LocationType } from '../../../types/location-type';
import { StatusAlertModel } from '../../../models/status/status-alert.model';

@Component({
  selector: 'gen-monorepo-modal-coupon-details',
  templateUrl: './modal-coupon-details.component.html',
  styleUrl: './modal-coupon-details.component.scss',
})
export class ModalCouponDetailsComponent {
  @Input() public reference: string = '';
  @Input() public id: string = '';

  public coupon: CouponFidelityModel = new CouponFidelityModel();
  public seller: SellerModel = new SellerModel();
  public lead: LeadModel = new LeadModel();
  public alertInfo: StatusAlertModel = new StatusAlertModel();

  public formGroup!: FormGroup;
  public location: LocationType;

  public ratingList: Array<CouponAvaliationsFidelityModel> = [];

  public isShowAvaliationCoupon: boolean = false;
  public isUseCouponOption: boolean = true;
  public isExpired: boolean = false;
  public openModal: boolean = false;
  public useScanner: boolean = true;
  public loading: boolean = true;
  public isMobile: boolean = window.innerWidth < 1200;
  public alreadyRescued: boolean = false;
  public showAlert: boolean = false;

  public leadAvaliationIndex: number = -1;
  public one: number;
  public two: number;
  public three: number;
  public four: number;
  public five: number;

  private destroy$ = new Subject();

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private $notification: StateManagementService,
    public modalRef: NzModalRef,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly $modal: NzModalService
  ) {}

  public ngOnInit(): void {
    this.getNotifications();
    this.updateAlertStatus();
    this.createForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      rating: new FormControl<number>(null, [Validators.required, Validators.min(1), Validators.max(5)]),
    });
  }

  private getNotifications(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
      }

      this.getCoupon(lead?.id);
    });
  }

  private getCoupon(leadId?: string): void {
    this.$lead
      .getCouponById(leadId, this.id)
      .pipe(
        mergeMap((res) => {
          if (res?.data?.couponLead) {
            this.coupon = JSON.parse(JSON.stringify(res.data.couponLead));

            const one = this.coupon?.coupon_avaliations.filter((avaliation) => avaliation.rating === 1);
            const two = this.coupon?.coupon_avaliations.filter((avaliation) => avaliation.rating === 2);
            const three = this.coupon?.coupon_avaliations.filter((avaliation) => avaliation.rating === 3);
            const four = this.coupon?.coupon_avaliations.filter((avaliation) => avaliation.rating === 4);
            const five = this.coupon?.coupon_avaliations.filter((avaliation) => avaliation.rating === 5);

            this.one = one.length;
            this.two = two.length;
            this.three = three.length;
            this.four = four.length;
            this.five = five.length;

            this.ratingList = [
              {
                message: '5',
                rating: this.five,
              },
              {
                message: '4',
                rating: this.four,
              },
              {
                message: '3',
                rating: this.three,
              },
              {
                message: '2',
                rating: this.two,
              },
              {
                message: '1',
                rating: this.one,
              },
            ];

            this.leadAvaliationIndex = this.coupon?.coupon_avaliations?.findIndex(
              (avaliation) => avaliation.leadId && avaliation.leadId === this.lead.id
            );
            this.isShowAvaliationCoupon = !!this.coupon.rescued || !!this.coupon.redeemed;
            this.isExpired = this.coupon.expiration_date ? new Date() > new Date(this.coupon.expiration_date) : false;

            if (this.openModal) {
              this.showSuccessModal();
            }

            if (this.leadAvaliationIndex >= 0) {
              const avaliation = this.coupon?.coupon_avaliations[this.leadAvaliationIndex];

              this.formGroup.get('rating').setValue(avaliation.rating);
              this.formGroup.get('rating').disable();
            }

            this.checkAlreadyRescued();
            return this.$lead.getCouponsBySellerId(this.coupon.sellerId, leadId);
          }

          return of(undefined);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.sellerToCoupon) {
            const parseSeller: SellerModel = JSON.parse(JSON.stringify(res.data.sellerToCoupon));
            parseSeller.receivingLimit = parseSeller?.coupons?.length;
            parseSeller.coupons = parseSeller?.coupons?.filter((coupon) => coupon.id !== this.id).slice(0, 5);
            this.seller = parseSeller;
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public chooseOption(type: string): void {
    if (type === 'USE') {
      this.isUseCouponOption = true;
    } else {
      this.isUseCouponOption = false;
    }
  }

  public likeCoupon(coupon: Partial<CouponFidelityModel>): void {
    if (this.lead.id) {
      if (coupon.isFavorite) {
        this.$lead
          .unlikeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.removeLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(
                error,
                'Erro ao retirar o like no cupom. Por favor, tente novamente mais tarde.'
              );
            },
          });
      } else {
        this.$lead
          .likeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.addLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(error, 'Erro ao dar like no cupom. Por favor, tente novamente mais tarde.');
            },
          });
      }
    } else {
      this.showLoginModal();
    }
  }

  private showSuccessModal(): void {
    this.openModal = false;
    this.$notification.setOpenModal(false);

    this.$modal.create({
      nzTitle: null,
      nzContent: ModalSuccessRedeemCouponComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: false,
      nzClassName: 'ant-modal-fullscreen',
      nzComponentParams: {
        coupon: this.coupon,
      },
    });
  }

  private showLoginModal(): void {
    this.$modal.create({
      nzTitle: null,
      nzContent: ModalLeadOnboardingComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: true,
      nzClassName: 'ant-modal-fullscreen',
    });
  }

  public checkAlreadyRescued() {
    this.$lead
      .getRescuedCoupons()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.leadRescuedCoupon) {
            const allCoupons = [].concat(
              res.data.leadRescuedCoupon?.actives || [],
              res.data.leadRescuedCoupon?.inactives || []
            );

            this.alreadyRescued = allCoupons.some((coupon) => coupon.id === this.coupon.id);
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;

          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public rescueCoupon(): void {
    if (this.lead.id) {
      this.$lead.rescueCoupon(this.coupon.id).subscribe({
        next: (res) => {
          if (res?.data?.linkCouponToLead) {
            this.showUseCouponModal();
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
    } else {
      this.showLoginModal();
    }
  }

  public showUseCouponModal(): void {
    if (this.lead.id) {
      this.modalRef.close();

      this.$modal.create({
        nzTitle: null,
        nzContent: ModalUseCouponComponent,
        nzFooter: null,
        nzCentered: true,
        nzClosable: true,
        nzClassName: 'ant-modal-fullscreen',
        nzWidth: '823px',
        nzComponentParams: {
          id: this.coupon.id,
        },
      });
    } else {
      this.showLoginModal();
    }
  }

  public goToAvaliations(): void {
    this.router.navigate([`/avaliations/${this.id}`]);
    this.modalRef.close();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 1200;
  }

  public updateAlertStatus(): void {
    this.$notification.sucessAlerts.subscribe((sucessAlerts) => {
      if (sucessAlerts) {
        this.alertInfo = sucessAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      if (errorAlerts !== null) {
        this.alertInfo = errorAlerts;
      }
    });
  }
}
