import { mergeMap, of } from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { LeadModel } from '../../../models/lead/lead.model';
import { CpfValidator } from '../../../validators/cpfValidator';
import { LeadService } from '../../../services/lead/lead.service';
import { EmailValidator } from '../../../validators/emailValidator';
import { FullNameValidator } from '../../../validators/fullNameValidator';
import { PhoneNumberValidator } from '../../../validators/phoneValidator';
import { StateManagementService } from '../../../state-management/state-management.service';
import { AuthService } from '../../../services/auth/auth-business/auth.service';

@Component({
  selector: 'app-modal-lead-onboarding',
  templateUrl: './modal-lead-onboarding.component.html',
  styleUrls: ['./modal-lead-onboarding.component.scss'],
})
export class ModalLeadOnboardingComponent implements OnInit {
  @Input() public phoneNumber: string = '';
  @Input() public couponId: string = '';

  public selectedIndex: number = 0;
  public loading: boolean = false;
  public formCreateAccount!: FormGroup;
  public formPhoneLogin!: FormGroup;
  public setNewPhone: boolean = false;
  public showAlert: boolean = false;
  public typeAlert: string = '';
  public alertMessage: string = '';

  constructor(
    private $lead: LeadService,
    private $auth: AuthService,
    private readonly fb: FormBuilder,
    private readonly modalRef: NzModalRef,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formCreateAccount = this.fb.group({
      name: new FormControl<string>('', [Validators.required, FullNameValidator.isValid()]),
      cpf: new FormControl<string>('', [Validators.required, CpfValidator.isValid()]),
      phoneNumber: new FormControl<string>('', [Validators.required, PhoneNumberValidator.isValid()]),
      email: new FormControl<string>('', [Validators.required, EmailValidator.isValid()]),
    });

    this.formPhoneLogin = this.fb.group({
      phoneNumber: new FormControl<string>('', [Validators.required, PhoneNumberValidator.isValid()]),
    });

    if (this.phoneNumber) {
      this.getInputValues();
    }
  }

  public getInputValues(): void {
    this.selectedIndex = 1;

    this.formCreateAccount.patchValue({
      phoneNumber: this.phoneNumber,
    });
  }

  public changeNumber(value: boolean) {
    this.setNewPhone = value;
  }

  public createAccount(): void {
    this.loading = true;

    const form = this.formCreateAccount.value;

    this.$lead
      .createLead(form.name, `+55${form.phoneNumber}`, form.email, form.cpf, 'COUPON_LEAD')
      .pipe(
        mergeMap((res) => {
          if (res?.data?.createLead) {
            return this.$auth.sendVerificationCode({ phoneNumber: `+55${form.phoneNumber}` }, 'SMS', 'LEAD');
          }

          this.openAlert('error', Message.ERROR_REGISTER_ACCOUNT);
          return of(undefined);
        })
      )
      .subscribe({
        next: (resp) => {
          if (resp?.data?.sendVerificationCode) {
            this.$notification.setPhoneNumber(`+55${form.phoneNumber}`);

            if (this.couponId) {
              this.$notification.setCouponId(this.couponId);
            }

            this.setNewPhone = true;
            this.openAlert('success', 'Conta criada com sucesso!');
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.openAlert('error', Message.ERROR_CONECTION, error);
        },
      });
  }

  public onSignIn(): void {
    this.loading = true;

    const phoneNumber = `+55${this.formPhoneLogin.get('phoneNumber').value}`;

    this.$auth.sendVerificationCode({ phoneNumber }, 'SMS', 'LEAD').subscribe({
      next: (res) => {
        if (res?.data?.sendVerificationCode) {
          this.$notification.setPhoneNumber(phoneNumber);
          this.setNewPhone = true;
          this.openAlert('success', 'Código enviado com sucesso!');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.openAlert('error', Message.LOGIN_ERROR, error);
      },
    });
  }

  public onChangeTab(index: number): void {
    this.formPhoneLogin.reset();
    this.formCreateAccount.reset();
    this.setNewPhone = false;
    this.selectedIndex = index;
  }

  public closeModal(value: boolean): void {
    this.modalRef.close(value);
  }

  public prepareAlertData(data: any): void {
    this.openAlert(data.type, data.message);
  }

  public openAlert(type: string, message: string, error?: any): void {
    if (error) {
      const parseError = JSON.parse(JSON.stringify(error));
      this.alertMessage =
        parseError.message.length > 50 ? parseError.message.substring(0, 50) + '...' : parseError.message;
    } else {
      this.alertMessage = message;
    }
    this.typeAlert = type;
    this.showAlert = true;

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
}
