<section class="preview_page">
  <header class="between p-t-1 margin-1">
    <time><b>10:00</b></time>
    <div class="icons">
      <img src="/assets/img/sim_status.svg" alt="Icone para internet movel" class="m-r-0" />
      <img src="/assets/img/wifi_status.svg" alt="Icone para Wifi" class="m-r-0" />
      <img src="/assets/img/battery.svg" alt="Icone para bateria" />
    </div>
  </header>

  <div nz-row class="margin-1">
    <div nz-col nzSpan="24" class="w-100 centerBlock">
      <h3 class="d-inline no-margin-bottom center">{{ coupon?.seller?.business_name || 'Nome da empresa' }}</h3>
    </div>
  </div>

  <div class="image-wrapper">
    <img
      [src]="coupon?.coupon_image || coupon?.seller?.image_url"
      onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
      class="coverImg"
      alt="Imagem do cupom."
    />
  </div>

  <div class="between wrapper-name margin-1">
    <h2 class="no-margin-bottom">{{ coupon?.name }}</h2>
    <div>
      <span nz-icon nzType="heart" nzTheme="outline" class="m-r-1"></span>
      <span nz-icon nzType="share-alt" nzTheme="outline"></span>
    </div>
  </div>

  <p class="description margin-1" [innerHTML]="coupon?.description"></p>

  <nz-card nzBorderless class="margin-1">
    <div class="d-flex">
      <div class="m-r-1">
        <img src="/assets/img/yellow-tag.svg" alt="Icone de uma tag amarela." />
      </div>
      <div>
        <div class="m-r-1">
          <span class="first-text">
            {{
              coupon?.typeDiscount === 'REAL_VALUE'
                ? (coupon?.value / 100 | currency : 'R$')
                : coupon?.value / 100 + '%'
            }}
            de desconto
          </span>
        </div>
        <div *ngIf="coupon?.buy_value_min">
          <span class="second-text">Em compras acima de {{ coupon?.buy_value_min / 100 | currency : 'R$' }}</span>
        </div>
      </div>
    </div>
  </nz-card>

  <div class="margin-1">
    <h3 class="m-b-1-2">Regras de uso</h3>

    <div class="d-block">
      <div class="d-flex m-b-1-2" *ngIf="coupon?.expiration_date">
        <div class="m-r-0">
          <span class="icon" nz-icon nzType="calendar"></span>
        </div>
        <div>
          <span>Cupom válido até {{ coupon?.expiration_date | date : 'dd/MM/yyyy' }}</span>
        </div>
      </div>
      <div class="d-flex m-b-1-2" *ngIf="coupon?.user_usage_limit">
        <div class="m-r-0">
          <span class="icon" nz-icon nzType="contacts"></span>
        </div>
        <div>
          <span>
            Válido para apenas {{ coupon.user_usage_limit > 1 ? coupon.user_usage_limit + ' compras' : '1 compra' }} por
            CPF
          </span>
        </div>
      </div>
      <div class="d-flex m-b-1-2" *ngIf="coupon?.buy_value_max">
        <div class="m-r-0">
          <span class="icon" nz-icon nzType="tag"></span>
        </div>
        <div>
          <span>Para compras acima de {{ coupon?.buy_value_min / 100 | currency : 'R$' }};</span>
          <br />
          <span *ngIf="coupon?.typeDiscount === 'PERCENT'">
            Desconto de até {{ coupon?.buy_value_max / 100 | currency : 'R$' }}
          </span>
        </div>
      </div>
      <div class="d-flex m-b-1-2">
        <div class="m-r-0">
          <span class="icon" nz-icon nzType="warning"></span>
        </div>
        <div>
          <span>Cupom sujeito a disponibilidade na loja</span>
        </div>
      </div>
    </div>
  </div>
  <div class="margin-1 p-b-2">
    <app-primary-button class="no-action-btn" [block]="true">Usar cupom</app-primary-button>
  </div>
</section>
