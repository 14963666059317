<nz-table
  id="main-table"
  #dynamicTable
  nzSize="middle"
  [nzSimple]="false"
  [nzBordered]="false"
  nzTableLayout="fixed"
  [nzData]="listOfData"
  [nzShowSizeChanger]="true"
  nzPaginationType="default"
  [nzHideOnSinglePage]="true"
  [nzPageIndex]="pageIndex"
  [nzPageSize]="pageSize"
  nzPaginationPosition="bottom"
  [nzWidthConfig]="widthConfig"
  [nzTotal]="settingValue?.totalItems"
  [nzFooter]="settingValue?.footer ? this[settingValue.footerName] : null"
  [nzShowPagination]="settingValue?.pagination"
  [nzPageSizeOptions]="[10, 25, 50, 100, 250]"
  [nzFrontPagination]="settingValue?.frontPagination ?? true"
  (nzCurrentPageDataChange)="currentPageDataChange($event)"
  (nzPageIndexChange)="onPageChange($event)"
  [ngClass]="platform === 'SYNAPSES' ? 'table-synapses' : ''"
  (nzPageSizeChange)="onPageSizeChange($event)"
  [nzScroll]="{ x: '1100' }"
>
  <thead>
    <ng-container *ngIf="!settingValue.removeHeader">
      <tr>
        <th
          *ngIf="settingValue.checkbox && settingValue.customHeader"
          nzWidth="60px"
          [(nzChecked)]="allChecked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="checkAll($event)"
          [ngClass]="
            settingValue.customHeader
              ? tableType === 'NOTIFICATIONS' || tableType === 'ORDER'
                ? 'customHeader'
                : tableType === 'SEGMENTATIONS_CAMPAIGN'
                ? 'customSegHeader'
                : ''
              : ''
          "
        ></th>
        <th
          *ngIf="settingValue.checkbox && !settingValue.customHeader"
          nzWidth="60px"
          [(nzChecked)]="allChecked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="checkAll($event)"
        ></th>
        <ng-container *ngIf="!settingValue.customHeader">
          <th
            nzRight
            *ngFor="let header of listOfHeaders"
            [nzSortFn]="header.compare"
            [nzSortPriority]="header.priority"
            [nzWidth]="header.headerWidth"
            [ngClass]="
              tableType === 'EXTERNAL_EXTRACT'
                ? 'external-extract-table'
                : tableType === 'WALLET_EXTRACT'
                ? 'external-extract-table'
                : tableType === 'SUBSCRIPTION'
                ? 'external-extract-table'
                : ''
            "
          >
            {{
              tableType === 'EXTERNAL_EXTRACT'
                ? header.name
                : tableType === 'WALLET_EXTRACT'
                ? header.name
                : tableType === 'SUBSCRIPTION'
                ? header.name
                : ''
            }}
            <div
              *ngIf="tableType !== 'EXTERNAL_EXTRACT' && tableType !== 'WALLET_EXTRACT' && tableType !== 'SUBSCRIPTION'"
              class="tooltip"
            >
              {{ header.name }}
              <span
                *ngIf="header.popover"
                nz-icon
                class="question-icon"
                nzType="question-circle"
                nzTheme="outline"
                nz-popover
                [nzPopoverContent]="popoverPrimary"
                nzPopoverPlacement="top"
                nzPopoverOverlayClassName="popoverPrimary"
              ></span>
              <ng-template #popoverPrimary>
                <div>
                  <p>
                    {{ header.popover.message }}
                  </p>
                  <app-primary-button *ngIf="header.popover.link" (click)="goToOutside(header.popover.link)">
                    Saiba mais
                  </app-primary-button>
                </div>
              </ng-template>
            </div>
          </th>
        </ng-container>
        <ng-container *ngIf="settingValue.customHeader">
          <th
            nzRight
            *ngFor="let header of listOfHeaders"
            [nzSortFn]="header.compare"
            [nzSortPriority]="header.priority"
            [nzWidth]="header.headerWidth"
            [ngClass]="
              settingValue.customHeader
                ? tableType === 'NOTIFICATIONS' || tableType === 'ORDER'
                  ? 'customHeader'
                  : tableType === 'SEGMENTATIONS_CAMPAIGN'
                  ? 'customSegHeader'
                  : 'external-extract-table'
                : ''
            "
          >
            {{ !header.action && !header.popover ? header.name : '' }}

            <div class="tooltip">
              {{ header.popover ? header.name : '' }}
              <span
                *ngIf="header.popover"
                nz-icon
                class="question-icon"
                nzType="question-circle"
                nzTheme="outline"
                nz-popover
                [nzPopoverContent]="popoverPrimary"
                nzPopoverPlacement="right"
                nzPopoverOverlayClassName="popoverPrimary"
              ></span>
              <ng-template #popoverPrimary>
                <div>
                  <p>
                    {{ header.popover.message }}
                  </p>
                  <app-primary-button *ngIf="header.popover.link" (click)="goToOutside(header.popover.link)">
                    Saiba mais
                  </app-primary-button>
                </div>
              </ng-template>
            </div>

            <div class="dots" *ngIf="header.action ? header.name : ''">
              {{ header.action ? header.name : '' }}

              <button
                [disabled]="!checkedData.length"
                nz-dropdown
                nzTrigger="click"
                [nzDropdownMenu]="
                  tableType === 'NOTIFICATIONS'
                    ? menu
                    : tableType === 'SUBSCRIPTION'
                    ? menuSubscription
                    : tableType === 'ORDER'
                    ? menuOrder
                    : null
                "
                *ngIf="header.action"
              >
                <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item (click)="callToAction('READ')">
                    <img src="/assets/images/icons/badge-check.svg" alt="Check" />
                    Marcar como lida
                  </li>
                  <li nz-menu-item (click)="callToAction('UNREAD')">
                    <img src="/assets/images/icons/close-circle.svg" alt="Fechar" />
                    Marcar como não lida
                  </li>
                  <li nz-menu-item (click)="callToAction('DELETE')">
                    <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                    Excluir
                  </li>
                </ul>
              </nz-dropdown-menu>
              <nz-dropdown-menu #menuSubscription="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item (click)="callToAction('EDIT')">
                    <img src="/assets/images/icons/pen.svg" alt="Editar fatura" />
                    Editar
                  </li>
                  <li nz-menu-item (click)="callToAction('CLONE')">
                    <img src="/assets/images/icons/clone.svg" alt="Duplicar fatura" />
                    Duplicar
                  </li>
                  <li nz-menu-item (click)="callToAction('DELETE')">
                    <img src="/assets/images/icons/trash.svg" alt="Excluir fatura" />
                    Excluir
                  </li>
                </ul>
              </nz-dropdown-menu>
              <nz-dropdown-menu #menuOrder="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item (click)="callToAction('PAID')">
                    <img src="/assets/images/icons/pen.svg" alt="ícone de lápis." />
                    Registrar pagamento
                  </li>
                  <li nz-menu-item (click)="callToAction('DELETE')">
                    <img src="/assets/images/icons/trash.svg" alt="Ícone de lixeira." />
                    Excluir
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </th>
        </ng-container>
      </tr>
    </ng-container>
  </thead>

  <tbody *ngIf="tableType === 'TRANSACTION'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.publicCustomer?.name || '-' }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.insertedAt | customDate }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.method | paymentMethod }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data.status" gender="female"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'TRANSACTION-BNPL'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>
          {{ data.payment?.publicCustomer?.name }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.subtotalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis>
          <app-status-tag
            [type]="
              data.payment?.status === 'PENDING'
                ? 'ONGOING'
                : data.payment?.status === 'CANCELLED'
                ? 'CANCELED'
                : 'HIRED'
            "
            gender="male"
          />
        </td>
        <td nzEllipsis>
          {{
            data.payment?.status === 'PENDING'
              ? 'Em andamento'
              : data.payment?.status === 'CANCELLED'
              ? 'Cancelado'
              : data.payment?.order?.deliveryStatus === 'DELIVERED'
              ? 'Entregue'
              : 'Entrega pendente'
          }}
        </td>
        <td>
          <app-status-tag [type]="data.status" gender="male" />
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'BNPL-SUBSELLER'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>
          {{ data.payment?.publicCustomer?.name || '-' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.order?.id }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.subtotalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td>
          <app-status-tag
            [type]="
              data.payment?.status === 'PENDING'
                ? 'ONGOING'
                : data.payment?.status === 'CANCELLED'
                ? 'CANCELED'
                : 'HIRED'
            "
            gender="male"
          />
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'BNPL-SALES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>
          {{ data.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis>
          {{ data.totalAmount | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.order?.id }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.seller?.id }}
        </td>
        <td>
          <app-status-tag [type]="data.payment?.order?.deliveryStatus" gender="female" />
        </td>
        <td>
          <app-status-tag [type]="data.payment?.status" gender="male" />
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'BNPL-RECEIPTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>
          {{ data.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis>
          {{ data.totalAmount | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.order?.id }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.seller?.id }}
        </td>
        <td nzEllipsis>
          <span [ngClass]="data.receivementStatus === 'LATE' ? 'error-color' : ''">
            {{ data.receivementPreviewDate | date : 'dd/MM/yyyy' }}
          </span>
        </td>
        <td>
          <app-status-tag [type]="data.payment?.order?.deliveryStatus" gender="female" />
        </td>
        <td>
          <app-status-tag [type]="data.payment?.order?.status" gender="male" />
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'BNPL-PAYMENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>
          {{ data.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis>
          {{ data.totalAmount | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.order?.id }}
        </td>
        <td nzEllipsis>
          {{ data.payment?.seller?.id }}
        </td>
        <td nzEllipsis>
          <span [ngClass]="data.liquidationStatus === 'LATE' ? 'error-color' : ''">
            {{ data.liquidationPreviewDate | date : 'dd/MM/yyyy' }}
          </span>
        </td>
        <td>
          <app-status-tag
            [type]="data.liquidationDate ? 'PAID' : 'PENDING'"
            gender="male"
            nz-tooltip
            nzTooltipPlacement="top"
            [nzTooltipTitle]="data.liquidationDate ? (data.liquidationDate | date : 'dd/MM/yyyy') : ''"
            nzTooltipColor="#6051e0"
          />
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'CONTACT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data?.fullName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.cpf | mask : '000.000.000-00') || (data?.cnpj | mask : '00.000.000/0000-00') }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data?.phoneNumber ? (data?.phoneNumber?.replace('+55', ' ') | mask : '(00) 00000-0000') : '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          <app-status-tag [type]="data.status || 'INCOMPLETE'" gender="male"></app-status-tag>
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
        <td class="end">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Deletar
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'CONTACT-SYNAPSES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.seller?.business_name || data?.seller?.trade_name || data?.business_name || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data?.fullName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.cpf | mask : '000.000.000-00') || (data?.cnpj | mask : '00.000.000/0000-00') }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data?.phoneNumber ? (data?.phoneNumber?.replace('+55', ' ') | mask : '(00) 00000-0000') : '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          <app-status-tag [type]="data.status || 'INCOMPLETE'" gender="male"></app-status-tag>
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
        <td class="end">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Deletar
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'CONTACT_SEGMENTATION'">
    <ng-container *ngFor="let data of dynamicTable.data" [class.selected]="data.checked">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.fullName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data.cpf | mask : '000.000.000-00') || (data.cnpj | mask : '00.000.000/0000-00') }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.phoneNumber?.replace('+55', ' ') | mask : '(00) 00000-0000') || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'CONTACT_CAMPAIGNS_SEND'">
    <ng-container *ngFor="let data of dynamicTable.data" [class.selected]="data.checked">
      <tr>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.fullName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data.cpf | mask : '000.000.000-00') || (data.cnpj | mask : '00.000.000/0000-00') }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.phoneNumber?.replace('+55', ' ') | mask : '(00) 00000-0000') || '-' }}
        </td>
        <td>
          <nz-tag [nzColor]="data.status === 'COMPLETE' ? 'green' : data.status === 'INCOMPLETE' ? 'purple' : ''">{{
            data.status === 'COMPLETE' ? 'Completo' : data.status === 'INCOMPLETE' ? 'Incompleto' : '-'
          }}</nz-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'SEGMENTATIONS_CAMPAIGN'">
    <ng-container *ngFor="let data of dynamicTable.data" [class.selected]="data.checked">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.name || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          <div class="center">
            {{ data.contacts?.length || '-' }}
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'SEGMENTATION'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>
          {{ data.name || '-' }}
        </td>
        <td nzEllipsis>
          {{ data.description || '-' }}
        </td>
        <td nzEllipsis>
          <span class="m-l-3">{{ data.contacts.length }}</span>
        </td>
        <td nzEllipsis>
          {{ (data.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Deletar
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'CONTACT_DETAILS_INVOICES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr (click)="showDetails(data)">
        <td nzEllipsis class="pointer-cursor">{{ data.id || '-' }}</td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.expirationDate | date : 'dd/MM/yyyy' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.totalCents / 100 | currency : 'R$' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          <nz-tag
            [nzColor]="
              data.status === 'PAID'
                ? 'green'
                : data.status === 'NOT_PAID'
                ? 'red'
                : data.status === 'CREATED'
                ? 'purple'
                : ''
            "
            >{{
              data.status === 'PAID'
                ? 'Paga'
                : data.status === 'NOT_PAID'
                ? 'Cancelada'
                : data.status === 'CREATED'
                ? 'Criada'
                : '-'
            }}</nz-tag
          >
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'CONTACT_DETAILS_SUBSCRIPTION'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr (click)="showDetails(data)">
        <td nzEllipsis class="pointer-cursor">{{ data.id?.substring(0, 25) + '...' || '-' }}</td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.startDate | date : 'dd/MM/yyyy' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.endDate / 100 | date : 'dd/MM/yyyy' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.totalCents / 100 | currency : 'R$' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          <nz-tag
            [nzColor]="
              data.status === 'waiting'
                ? 'gold'
                : data.status === 'active'
                ? 'green'
                : data.status === 'CREATED'
                ? 'purple'
                : ''
            "
            >{{
              data.status === 'waiting'
                ? 'Aguardando pagamento'
                : data.status === 'active'
                ? 'Ativo'
                : data.status === 'CREATED'
                ? 'Criada'
                : '-'
            }}</nz-tag
          >
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'CONTACT_DETAILS_PAYMENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr (click)="showDetails(data)">
        <td nzEllipsis class="pointer-cursor">{{ data.id?.substring(0, 25) + '...' || '-' }}</td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.insertedAt | date : 'dd/MM/yyyy' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.totalCents / 100 | currency : 'R$' || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          <nz-tag
            [nzColor]="
              data.status === 'PAID'
                ? 'green'
                : '' || data.status === 'WAITING'
                ? 'gold'
                : '' || data.status === 'CANCELLED'
                ? 'red'
                : ''
            "
            >{{
              data.status === 'PAID'
                ? 'Pago'
                : '' || data.status === 'WAITING'
                ? 'Aguardando pagamento'
                : '' || data.status === 'CREATED'
                ? 'Criada'
                : '' || data.status === 'CANCELLED'
                ? 'Cancelada'
                : '' || '-'
            }}</nz-tag
          >
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'COMPANY'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.businessName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.cnpj | mask : '00.000.000/0000-00' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.phoneNumber?.replace('+55', '') | mask : '(00) 00000-0000') || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          <app-status-tag [type]="data.status || 'INCOMPLETE'" gender="male"></app-status-tag>
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
        <td class="end">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Deletar
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'COMPANY-SYNAPSES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.seller?.business_name || data?.seller?.trade_name || data?.business_name || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.businessName || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ data.cnpj | mask : '00.000.000/0000-00' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.phoneNumber?.replace('+55', '') | mask : '(00) 00000-0000') || '-' }}
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          <app-status-tag [type]="data.status || 'INCOMPLETE'" gender="male"></app-status-tag>
        </td>
        <td (click)="showDetails(data)" nzEllipsis class="pointer-cursor">
          {{ (data?.created_at | date : 'dd/MM/yyyy') || '-' }}
        </td>
        <td class="end">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Deletar
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'TRANSACTION_DETAILS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.subtotalCents | currency : 'R$' }}</td>
        <td nzEllipsis>{{ data.percentageTariff }}%</td>
        <td nzEllipsis>{{ data?.discountCents | currency : 'R$' }}</td>
        <td nzEllipsis>
          {{
            data?.credit?.installments
              ? data.credit.installments
              : data?.bnplCheckout?.numberOfInstallments
              ? data.bnplCheckout.numberOfInstallments
              : 1
          }}x
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ITEM'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.name }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.itemCategory?.name || '' }}</td>
        <td nzEllipsis class="pointer-cursor center" (click)="showDetails(data)">{{ data?.totalStockCount || 0 }}</td>
        <td nzEllipsis class="pointer-cursor center" (click)="showDetails(data)">
          {{ data?.itemVariants?.length || 0 }}
        </td>
        <td nzEllipsis class="pointer-cursor center" (click)="showDetails(data)">
          {{ (data.itemUnits?.length > 0 ? data.itemUnits[0].price : 0) / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <nz-tag [nzColor]="data.itemUnits?.length > 0 ? (data.itemUnits[0].active ? 'green' : 'red') : 'red'">{{
            data.itemUnits?.length > 0 ? (data.itemUnits[0].active ? 'Ativo' : 'Inativo') : 'Inativo'
          }}</nz-tag>
        </td>
        <td class="end">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="Ícone de três pontos na vertical" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul class="option-menu" nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="../../../assets/images/icons/pen.svg" alt="Ícone de lápis" />
                <span class="m-l-0-1">Editar</span>
              </li>
              <li nz-menu-item (click)="callToAction('STORE', data)">
                <img width="21" src="/assets/images/icons/receipt-alt.svg" alt="Ícone de comprovante" />
                <span class="m-l-0-1">Copiar link do catálogo</span>
              </li>
              <li nz-menu-item (click)="callToAction('DUPLICATE', data)">
                <img width="21" src="/assets/images/icons/clone.svg" alt="Ícone de duplicar" />
                <span style="margin-left: 9px" class="colorDefault">Duplicar</span>
              </li>
              <li nz-menu-item (click)="callToAction('COPY', data)" [nzDisabled]="seller.status !== 'ACTIVE'">
                <img width="21" src="/assets/images/icons/receipt-alt-two.svg" alt="Ícone de comprovante" />
                <span style="margin-left: 10px" class="colorDefault">Copiar link de checkout</span>
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img width="21" src="/assets/images/icons/trash.svg" alt="Ícone de lixeira" />
                <span style="margin-left: 11px" class="colorDefault">Excluir</span>
              </li>
              <li nz-menu-item (click)="callToAction('SHOW', data)">
                <img width="21" src="/assets/images/icons/check.svg" alt="Ícone de sucesso" />
                <span style="margin-left: 10px" class="colorDefault">Ativar/desativar no catálogo</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ITEM-SYNAPSES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{
            data?.seller?.store?.displayName || data?.seller?.company?.businessName || data?.seller?.person?.name || '-'
          }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.name }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.itemCategory?.name || '' }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.totalStockCount || 0 }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ (data.itemUnits?.length > 0 ? data.itemUnits[0].price : 0) / 100 | currency : 'R$' }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data)">
          <nz-tag [nzColor]="data.itemUnits?.length > 0 ? (data.itemUnits[0].active ? 'green' : 'red') : 'red'">
            {{ data.itemUnits?.length > 0 ? (data.itemUnits[0].active ? 'Ativo' : 'Inativo') : 'Inativo' }}
          </nz-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ITEM-DETAILS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor">
          <nz-tag [nzColor]="data.active ? 'green' : 'red'">{{ data.active ? 'Ativo' : 'Desativado' }}</nz-tag>
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.itemOptions.name }}
        </td>
        <td nzEllipsis class="pointer-cursor">{{ data.itemOptions.name }}</td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.amountCents / 100 | currency : 'R$' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ORDER'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.id }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.customer?.name }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.insertedAt | customDate }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{
            data.deliveryMethod === 'DELIVERY'
              ? 'Entrega'
              : '' || data.deliveryMethod === 'LOCKER'
              ? 'Locker'
              : '' || data.deliveryMethod === 'PICK_AND_GO'
              ? 'Retirada'
              : ''
          }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.status"></app-status-tag>
        </td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('PAID', data)">
                <img src="/assets/images/icons/pen.svg" alt="Ícone de lápis." />
                Registrar pagamento
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Ícone de lixeira." />
                Excluir
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SIMPLE_INVOICE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.customerData?.name || '-' }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.id }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.expirationDate | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.status.toUpperCase() || ''" gender="female"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SUBSCRIPTION'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="external-extract">
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.customer?.name }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.id.substring(0, 8) }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.expirationDate || data.endDate || data.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.status.toUpperCase() || ''" gender="female"></app-status-tag>
        </td>
        <td class="endBlock line-height">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="Ícone de três pontos na vertical" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                [nzDisabled]="data.schedulePlans ? data?.status === 'INACTIVE' : data?.status !== 'CREATED'"
                nz-tooltip
                [nzTooltipTitle]="
                  data.schedulePlans
                    ? data?.status === 'INACTIVE'
                      ? 'Essa fatura recorrente já está inativada.'
                      : ''
                    : data?.status !== 'CREATED'
                    ? 'Faturas pagas ou canceladas não podem ser editadas.'
                    : ''
                "
                nzTooltipColor="#6051e0"
                (click)="
                  data.schedulePlans
                    ? data?.status === 'INACTIVE'
                      ? null
                      : callToAction('EDIT', data)
                    : data?.status !== 'CREATED'
                    ? null
                    : callToAction('EDIT', data)
                "
              >
                <img src="/assets/images/icons/pen.svg" alt="Ícone de lápis" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('COPY', data)" [nzDisabled]="seller.status !== 'ACTIVE'">
                <img width="21" src="/assets/images/icons/receipt-alt-two.svg" alt="Ícone de comprovante" />
                <span class="colorDefault">Copiar link de checkout</span>
              </li>
              <li
                nz-menu-item
                [nzDisabled]="data.schedulePlans ? data?.status === 'INACTIVE' : data?.status !== 'CREATED'"
                nz-tooltip
                [nzTooltipTitle]="
                  data.schedulePlans
                    ? data?.status === 'INACTIVE'
                      ? 'Essa fatura recorrente já está inativada.'
                      : ''
                    : data?.status !== 'CREATED'
                    ? 'Faturas pagas ou canceladas não podem ser excluídas.'
                    : ''
                "
                nzTooltipColor="#6051e0"
                (click)="
                  data.schedulePlans
                    ? data?.status === 'INACTIVE'
                      ? null
                      : callToAction('DELETE', data)
                    : data?.status !== 'CREATED'
                    ? null
                    : callToAction('DELETE', data)
                "
              >
                <img width="21" src="/assets/images/icons/trash.svg" alt="Ícone de lixeira" />
                {{ data.schedulePlans ? 'Inativar' : 'Excluir' }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SALES_REPORT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis nzWidth="60%">{{ data.description }}</td>
        <td nzWidth="20%">{{ data.quantity }}</td>
        <td nzWidth="20%">{{ data.totalCents / 100 | currency : 'R$' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PAYMENT_REPORT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.method | paymentMethod }}</td>
        <td nzEllipsis>{{ data.countPayments }}</td>
        <td nzEllipsis>{{ data.countRefunds }}</td>
        <td nzEllipsis>{{ data.totalPayments | currency : 'R$' }}</td>
        <td nzEllipsis>{{ data.totalRefunds | currency : 'R$' }}</td>
        <td nzEllipsis>{{ data.totalAmount | currency : 'R$' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'BALANCE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.name }}</td>
        <td nzEllipsis>{{ data.agencyNumber }}</td>
        <td nzEllipsis>{{ data.accountNumber }}</td>
        <td nzEllipsis>{{ data.bankNumber }}</td>
        <td nzEllipsis>{{ data.type }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'EDITABLE_TABLE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data?.description }}</td>
        <td nzEllipsis>{{ data?.quantity }}</td>
        <td nzEllipsis>{{ data?.unitPriceCents / 100 | currency : 'R$' }}</td>
        <td nzEllipsis>
          {{ (data.unitPriceCents / 100) * data.quantity + (data?.shippingCents / 100 || 0) | currency : 'R$' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SUBSCRIPTION_PAYMENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>
          {{ data.totalCents / 100 | currency : 'R$' }}
          <app-status-tag [type]="data?.status?.toUpperCase() || ''" gender="female" class="pl-10"></app-status-tag>
        </td>
        <td nzEllipsis>{{ data.method }}</td>
        <td nzEllipsis>{{ data.id }}</td>
        <td nzEllipsis>{{ data.confirmedAt | date : 'dd/MM/yyyy' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PAYMENT_LINK'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.url }}
        </td>
        <td nzEllipsis>
          <span
            nz-icon
            nzType="copy"
            nzTheme="outline"
            class="primary-color pointer-cursor"
            (click)="callToAction('COPY', data)"
          ></span>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <span *ngIf="data.type === 'DETACHED'">{{ data?.description || 'Pagamento avulso' }} </span>
          <span *ngIf="data.type === 'ITEM_SELL'">
            {{ data?.items?.[0]?.name || data?.items?.[0]?.description }}
          </span>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <span *ngIf="data.type === 'DETACHED'">
            {{ data.amountCents / 100 | currency : 'R$' }}
          </span>
          <span *ngIf="data.type === 'ITEM_SELL'">
            {{ data.items?.[0]?.amountCents / 100 | currency : 'R$' }}
          </span>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
        </td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Excluir
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PLANS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.name }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <span *ngFor="let item of data.prices; let i = index">
            {{ item?.amountCents / 100 | currency : 'R$' }}
            <i *ngIf="i + 1 < data.prices.length" class="p-r-1">/</i>
          </span>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.visible ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
        </td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/images/icons/pen.svg" alt="Caneta" />
                Editar
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Excluir
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'DOCUMENT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.document }}</td>
        <td nzEllipsis>
          <app-status-tag *ngIf="!data.loading" [type]="data.status"></app-status-tag>
        </td>
        <td nzEllipsis>
          <ng-container *ngIf="data.status === 'PENDING_DOC'">
            <app-secondary-button
              icon="upload"
              (click)="fileInputDocs.click()"
              [loading]="data.loading"
              [disabled]="data.loading"
            >
              Anexar imagem
            </app-secondary-button>
            <input
              hidden
              (change)="onSaveImage($event, data)"
              #fileInputDocs
              type="file"
              accept=".png,.jpeg,.bmp,.jpg,.pdf"
            />
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PIX_EXTRACT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td class="pointer-cursor" (click)="showDetails(data)" nzEllipsis>
          {{ data.updatedAt | date : 'dd/MM/YYYY' }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data)" nzEllipsis>
          {{ data?.publicCustomer?.name || '-' }}
        </td>
        <td class="pointer-cursor center" (click)="showDetails(data)" nzEllipsis>
          {{ (data?.publicCustomer?.cpf | hidePartialCpf) || (data?.publicCustomer?.cnpj | hidePartialCNPJ) || '-' }}
        </td>
        <td class="pointer-cursor center" (click)="showDetails(data)" nzEllipsis>
          {{ data?.id }}
        </td>
        <td class="pointer-cursor center" (click)="showDetails(data)" nzEllipsis>
          <span [ngClass]="data.status === 'CANCELLED' ? 'negative' : 'positive'">
            {{
              data?.status === 'CANCELLED'
                ? (data?.totalCents / 100 | currency : '- R$')
                : (data?.totalCents / 100 | currency : '+ R$')
            }}
          </span>
        </td>
        <td class="end">
          <a class="pointer-cursor" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                [nzDisabled]="disablePixDevolutuin(data)"
                nz-tooltip
                [nzTooltipTitle]="
                  disablePixDevolutuin(data)
                    ? 'A devolução é limitada a transações realizadas nos últimos 90 dias. / Somente Pix pagos podem serem devolvidos.'
                    : ''
                "
                nzTooltipColor="#6051e0"
                (click)="callToAction('reverse-value', data)"
              >
                Devolver valor
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'STAFF'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="d-flex">
          <div class="center centerBlock items-center">
            <div class="auth-circle-table">
              <div class="initial-letter-table">
                <span>{{ data?.name?.charAt(0)?.toUpperCase() }}</span>
              </div>
            </div>
          </div>

          <span class="strong-text">
            {{ data.name }}
          </span>
        </td>
        <td nzEllipsis>{{ data.email }}</td>
        <td nzEllipsis>
          {{ data.document | mask : '000.000.000-00' }}
        </td>
        <td nzEllipsis>
          <div class="roles-div">
            <span>{{ getUserRole(data.role) }}</span>
          </div>
        </td>

        <td nzEllipsis>{{ data.insertedAt | date : 'dd/MM/YYYY' }}</td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/icons/dots-horizontal.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/icons/primary-edit.svg" alt="Icone de lapis" />
                <span class="primary-color m-l-0-1">Editar</span>
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/icons/red-trash.svg" alt="Lixeira" />
                <span class="error-color m-l-0-1">Excluir</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SELLERS_USERS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data?.users?.name }}</td>
        <td nzEllipsis>
          {{ data?.users?.cpf | mask : '000.000.000-00' }}
        </td>
        <td nzEllipsis>{{ data?.insertedAt | date : 'dd/MM/YYYY' }}</td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', data)">
                <img src="/assets/icons/primary-edit.svg" alt="Icone de lapis" />
                <span class="primary-color m-l-0-1">Editar</span>
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/icons/red-trash.svg" alt="Lixeira" />
                <span class="error-color m-l-0-1">Excluir</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'NOTIFICATIONS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" [ngClass]="data.read === false ? ' notification-body__unread' : 'notification-body'">
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="showDetails(data)" nzEllipsis>{{ data.description || '-' }}</td>
        <td (click)="showDetails(data)">
          {{ data.type }}
        </td>
        <td (click)="showDetails(data)">
          <span *ngIf="data?.date?.seconds && !data?.insertedAt" class="menu-notification">
            {{ data?.date?.seconds * 1000 | date : 'dd/MM/YYYY' }}
          </span>
          <span *ngIf="!data?.date?.seconds && !data?.insertedAt" class="menu-notification">
            {{ data?.date | date : 'dd/MM/YYYY' }}
          </span>
          <span *ngIf="data?.insertedAt?.seconds" class="menu-notification">
            {{ data?.insertedAt?.seconds * 1000 | date : 'dd/MM/YYYY' }}
          </span>
          <span *ngIf="data?.insertedAt && !data?.insertedAt?.seconds" class="menu-notification">
            {{ data?.insertedAt | date : 'dd/MM/YYYY' }}
          </span>
        </td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('READ', data)">
                <img src="/assets/images/icons/badge-check.svg" alt="Check" />
                Marcar como lida
              </li>
              <li nz-menu-item (click)="callToAction('UNREAD', data)">
                <img src="/assets/images/icons/close-circle.svg" alt="Fechar" />
                Marcar como não lida
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', data)">
                <img src="/assets/images/icons/trash.svg" alt="Lixeira" />
                Excluir
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'CATEGORY'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="table-category">{{ data?.name || '' }}</td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/edit.svg" class="m-r-2" alt="Seta para a direita" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="primary-color" (click)="callToAction('EDIT', data)">Editar</li>
              <li nz-menu-item class="primary-color" (click)="callToAction('ASSOCIATE', data)">Destinar itens</li>
              <li nz-menu-item class="error-color" (click)="callToAction('DELETE', data)">Deletar</li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'VARIATION'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis>{{ data?.name || '' }}</td>
        <td nzEllipsis>{{ data?.typesName || '' }}</td>
        <td nzEllipsis>{{ data?.itemsCount || '' }}</td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="primary-color" (click)="callToAction('EDIT', data)">Editar</li>
              <li nz-menu-item class="error-color" (click)="callToAction('DELETE', data)">Deletar</li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ITEM-VARIANT'">
    <ng-container *ngFor="let data of dynamicTable.data; let i = index">
      <tr>
        <td nzEllipsis>{{ data?.name || '' }}</td>
        <td nzEllipsis>{{ data?.itemVariantOptionsNames || '' }}</td>
        <td class="endBlock">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img src="../../assets/images/icons/dots-vertical.svg" alt="dots-icon" />
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="callToAction('EDIT', i)">
                <img src="../../../assets/images/icons/primary-edit.svg" alt="Ícone de lápis" />
                <span class="primary-color m-l-0-1">Editar</span>
              </li>
              <li nz-menu-item (click)="callToAction('DELETE', i)">
                <img src="../../../assets/images/icons/red-trash.svg" alt="Ícone de lápis" />
                <span class="error-color m-l-0-1">Deletar</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'COUPON'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.code || '' }}</td>
        <td nzEllipsis *ngIf="data.type === 'PERCENT'">{{ data.percent }}%</td>
        <td nzEllipsis *ngIf="data.type === 'CENTS'">{{ data.discountCents / 100 | currency : 'R$' }}</td>
        <td nzEllipsis>
          <ng-container *ngIf="data?.maxCoupons === 0; else hasData">
            {{ data?.couponDiscountsCount || 0 }} /
            <img class="wh-15" src="/assets/images/Infinity.svg" alt="Icone do infinito" />
          </ng-container>
          <ng-template #hasData> {{ data?.couponDiscountsCount || 0 }} / {{ data?.maxCoupons }} </ng-template>
        </td>
        <td nzEllipsis>{{ (data?.startDate | customDate) || '-' }}</td>
        <td nzEllipsis>{{ (data?.endDate | customDate) || '-' }}</td>
        <td>
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <span nz-icon class="default-icon" nzType="ellipsis" nzTheme="outline"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="primary-color" (click)="callToAction('EDIT', data)">Editar</li>
              <li nz-menu-item class="primary-color" (click)="callToAction('DUPLICATE', data)">Duplicar</li>
              <li nz-menu-item class="error-color" (click)="callToAction('DELETE', data)">Deletar</li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PROFILE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.agencyNumber }}</td>
        <td nzEllipsis>{{ data.accountNumber | mask : '00000-0' }}</td>
        <td nzEllipsis>{{ data.bankName }}</td>
        <td nzEllipsis>{{ data.type === 'CHECKING' ? 'Conta corrente' : 'Conta poupança' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'WEBHOOKS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>{{ data.target }}</td>
        <td nzEllipsis>{{ data.events }}</td>
        <td nzEllipsis>{{ data.insertedAt | customDate }}</td>
        <td>
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <span nz-icon class="default-icon" nzType="ellipsis" nzTheme="outline"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="primary-color" (click)="callToAction('EDIT', data)">Editar</li>
              <li nz-menu-item class="error-color" (click)="callToAction('DELETE', data)">Deletar</li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'DEVELOPER_EVENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>{{ data.type }}</td>
        <td nzEllipsis>{{ data.insertedAt | customDate }}</td>
        <td nzEllipsis>{{ data.updatedAt | customDate }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'DEVELOPER_EVENTS_DATAIL'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr (click)="showDetails(data)">
        <td nzEllipsis>{{ data?.target }}</td>
        <td nzEllipsis>{{ data?.attempts }}</td>
        <td nzEllipsis>
          {{ data.lastResponse?.status }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'WALLET_EXTRACT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr (click)="callToAction('SHOW', data)" class="external-extract">
        <td>
          <div nzEllipsis>{{ data?.formatedDescription }}</div>
          <small *ngIf="data.sellerName">
            {{ data.seller?.company?.businessName || data.seller?.person?.name || 'Taxa geral' }}
          </small>
        </td>
        <td nzEllipsis>
          {{ data.amount / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.fee / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          {{ data.type === 'CREDIT' ? (data.total / 100 | currency : 'R$') : '' }}
        </td>
        <td nzEllipsis>
          {{ data.type === 'DEBIT' ? (data.total / 100 | currency : 'R$') : '' }}
        </td>
        <td nzEllipsis>
          {{ data.date | date : 'dd/MM/yyyy' }}
        </td>
        <td>
          <app-status-tag [type]="data.payment?.status || 'SETTLED'" page="extract"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'EXTERNAL_EXTRACT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr
        class="external-extract"
        (click)="callToAction('SHOW', data)"
        [class]="data.payment?.id ? 'pointer-cursor' : ''"
      >
        <td>
          <div nzEllipsis>{{ data?.formatedDescription || '-' }}</div>
        </td>
        <td>
          {{ data.order?.id && isStatusCancelled(data.status) ? 'cc.' : '' }}
          {{ data.order?.id ? data.order?.customerData?.name : '' }}
        </td>
        <td nzEllipsis>
          {{
            data.payment?.credit
              ? data.installment + '/' + data.payment?.credit?.installments
              : data.installment + '/' + data.description.substring(data.description.length - 1)
          }}
        </td>
        <td nzEllipsis>
          {{
            !isStatusCancelled(data?.status)
              ? (data.amount / 100 | currency : 'R$')
              : '- ' + (data.amount / 100 | currency : 'R$')
          }}
        </td>
        <td nzEllipsis>
          {{
            !isStatusCancelled(data?.status)
              ? (data.totalReceivable / 100 | currency : 'R$')
              : '- ' + (data.totalReceivable / 100 | currency : 'R$')
          }}
        </td>
        <td nzEllipsis>
          {{ data.date | date : 'dd/MM/yyyy' }}
        </td>
        <td>
          <app-status-tag [type]="data?.payment?.status || 'SETTLED'" page="extract"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'EVENTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor">{{ data.title }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.storeName }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.datetimeBegin | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.datetimeEnd | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
      <td nzEllipsis class="pointer-cursor">
        <app-status-tag [type]="data.avaliable ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PENDING-COMPANY'">
    <tr *ngFor="let data of dynamicTable.data" (click)="showDetails(data)">
      <td nzEllipsis class="pointer-cursor">{{ data.date | date : 'dd/MM/yyyy' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.company }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.cnpj }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.users }}</td>
      <td nzEllipsis class="pointer-cursor">
        <app-status-tag [type]="data.status ? 'PENDING' : 'REGISTER'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-cursor">{{ data.pendingNotes }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.approvedNotes }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PENDING-COMPANY-DETAILS'">
    <tr *ngFor="let data of dynamicTable.data" (click)="showDetails(data)">
      <td nzEllipsis class="pointer-cursor">{{ data.date | date : 'dd/MM/yyyy' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.nfCode }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.statusSefaz }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.type }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.value }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.userId }}</td>
      <td nzEllipsis class="pointer-cursor">
        <app-status-tag [type]="data.statusGen ? 'PENDING' : 'REGISTER'" gender="male"></app-status-tag>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'CUSTOMER'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
        <app-status-tag [type]="data.status ? data.status : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.name || '-' }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.cpf | mask : '000.000.000-00' }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data?.email || '-' }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data?.phone?.replace('+55', '') | mask : '(00) 0 0000-0000' || '-' }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
        {{ (data.insertedAt | date : 'dd/MM/yyyy') || '-' }}
      </td>
      <td>
        <div class="centerBlock" *ngIf="data.loading">
          <nz-spin class="spinner centerBlock" nzSize="small"></nz-spin>
        </div>

        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" *ngIf="!data.loading">
          <img src="/assets/images/icons/dots-vertical-primary.svg" alt="Ícone de três pontos na vertical" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul class="option-menu" nz-menu>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="m-l-0-1">Editar informações</span>
            </li>
            <li nz-menu-item (click)="callToAction('REPROCESS', data)">
              <img width="21" src="/assets/images/icons/reprocess.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1">Reprocessar</span>
            </li>
            <li nz-menu-item (click)="callToAction('APROVE', data)">
              <img width="21" src="/assets/images/icons/user-green.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 positive">Aprovar</span>
            </li>
            <li nz-menu-item (click)="callToAction('REPROVE', data)">
              <img width="21" src="/assets/images/icons/reprove.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Reprovar</span>
            </li>
            <li nz-menu-item (click)="callToAction('BAN', data)">
              <img width="21" src="/assets/images/icons/ban.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Banir</span>
            </li>
            <li nz-menu-item (click)="callToAction('BLOCK', data)" *ngIf="data?.status !== 'BLOCKED'">
              <img width="21" src="/assets/images/icons/block.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Bloquear</span>
            </li>
            <li nz-menu-item (click)="callToAction('UNBLOCK', data)" *ngIf="data?.status === 'BLOCKED'">
              <img width="21" src="/assets/images/icons/user-green.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 positive">Desbloquear</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'USERS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
        <td [nzDisabled]="data.disabled" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data?.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.name || '-' }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.cpf | mask : '000.000.000-00' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.phone ? (data.phone.replace('+55', '') | mask : '(00) 0 0000-0000') : '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.insertedAt | customDate : 'DD/MM/YYYY' : '-' }}
        </td>
      </tr>
      <tr [nzExpand]="expandSet.has(data.id)">
        <div class="sellers" *ngIf="data?.sellers.length > 0">
          <ul>
            <li>
              <strong>Sellers vinculados:</strong>
              <ul>
                <li *ngFor="let seller of data?.sellers">
                  {{ seller?.company?.businessName || seller?.person?.name }} -
                  {{ seller?.cnpj | mask : '00.000.000/0000-00' || seller?.cpf | mask : '000.000.000-00' }}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="customers" *ngIf="data?.customers.length > 0">
          <ul>
            <li>
              <strong>Customers vinculados:</strong>
              <ul>
                <li *ngFor="let customer of data?.customers">
                  {{ customer?.name }} - {{ customer?.cpf | mask : ' 000.000.000-00' }}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="empty-data center" *ngIf="!data?.customers.length && !data?.sellers.length">
          <h3>Não há dados</h3>
        </div>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'SELLERS_CAMPAIGN'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.displayName || data?.company?.businessName || data?.person?.name || '-' }}
        </td>
        <td nzEllipsis (click)="showDetails(data)">
          {{ data.cnpj ? (data.cnpj | mask : '00.000.000/0000-00') : (data.cpf | mask : '000.000.000-00') }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.phone ? (data.phone.replace('+55', '') | mask : '(00) 0 0000-0000') : '-' }}
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'SELLERS_CAMPAIGN_DETAILS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data?.displayName || data?.company?.businessName || data?.person?.name || '-' }}
        </td>
        <td nzEllipsis (click)="showDetails(data)">
          {{ data.cnpj ? (data.cnpj | mask : '00.000.000/0000-00') : (data.cpf | mask : '000.000.000-00') }}
        </td>
        <td nzEllipsis class="pointer-cursor startBlock start" (click)="showDetails(data)">
          {{ data?.phone ? (data.phone.replace('+55', '') | mask : '(00) 0 0000-0000') : '-' }}
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'CUSTOMERS_ESTABLISHMENTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>-</td>
      <td nzEllipsis>-</td>
      <td nzEllipsis>-</td>
      <td nzEllipsis>
        <app-status-tag [type]="data.status" gender="male"></app-status-tag>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'DOCUMENTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis><app-status-tag [type]="data.status" gender="male"></app-status-tag></td>
      <td nzEllipsis>{{ data.type | document }}</td>
      <td nzEllipsis>{{ data.insertedAt | customDate : 'DD/MM/YYYY HH:mm' }}</td>
      <td>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <span nz-icon class="default-icon" nzType="ellipsis" nzTheme="outline"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              *ngIf="data.status !== 'approved'"
              nz-menu-item
              class="primary-color"
              (click)="callToAction('APPROVE', data)"
            >
              Aprovar
            </li>
            <li
              *ngIf="data.status !== 'rejected'"
              nz-menu-item
              class="error-color"
              (click)="callToAction('REJECT', data)"
            >
              Rejeitar
            </li>
            <li nz-menu-item class="error-color" (click)="callToAction('VIEW', data)">
              <a [href]="data.url" class="d-flex" target="_blank">
                <span nz-icon class="default-icon m-r-0" nzType="eye" nzTheme="outline"></span>
                Ver documento
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PARTICIPANTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>
        <app-status-tag [type]="data.usedTicket ? 'RESCUED' : 'NOT_RESCUED'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis>{{ data.participant }}</td>
      <td nzEllipsis>{{ data.createdAt | customDate : 'DD/MM/YYYY' }}</td>
      <td>
        <span class="error-color pointer-cursor" (click)="callToAction(data.id)">Cancelar </span>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'CUSTOMER_LIMITS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>{{ data.action.name }}</td>
      <td nzEllipsis>{{ data.functionality?.name }}</td>
      <td nzEllipsis>{{ data.category?.name }}</td>
      <td nzEllipsis>{{ data.amount | currency : 'R$' }}</td>
      <td nzEllipsis>{{ data.perPeriod?.name }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'FINANCIAL'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>{{ data.data | date : 'dd/MM/yyyy' }}</td>
      <td nzEllipsis>{{ data.estabelecimento }}</td>
      <td nzEllipsis>{{ data.customer }}</td>
      <td nzEllipsis>{{ data.valor_pago | currency : 'R$' }}</td>
      <td nzEllipsis>{{ data.id }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'OPERATIONAL'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>{{ data.data | date : 'dd/MM/yyyy' }}</td>
      <td nzEllipsis>{{ data.estabelecimento }}</td>
      <td nzEllipsis>{{ data.code }}</td>
      <td nzEllipsis>{{ data.customer }}</td>
      <td nzEllipsis>{{ data.valor_pago | currency : 'R$' }}</td>
      <td nzEllipsis>{{ data.valor_isento | currency : 'R$' }}</td>
      <td nzEllipsis>{{ data.id }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'SELLERS'">
    <tr *ngFor="let data of dynamicTable.data" class="pointer-cursor">
      <td (click)="showDetails(data)">
        <app-status-tag [type]="data.status" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data?.displayName || data?.company?.businessName || data?.person?.name || '-' }}
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data.cnpj ? (data.cnpj | mask : '00.000.000/0000-00') : (data.cpf | mask : '000.000.000-00') }}
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data.insertedAt || '-' | date : 'dd/MM/yyyy' }}
      </td>
      <td>
        <div class="centerBlock" *ngIf="data.loading">
          <nz-spin class="spinner centerBlock" nzSize="small"></nz-spin>
        </div>

        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" *ngIf="!data.loading">
          <img src="/assets/images/icons/dots-vertical-primary.svg" alt="Ícone de três pontos na vertical" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul class="option-menu" nz-menu>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="m-l-0-1">Editar informações</span>
            </li>
            <li nz-menu-item (click)="callToAction('PERSONALIZATION', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="m-l-0-1">Personalizar Loja</span>
            </li>
            <li nz-menu-item (click)="callToAction('APROVE', data)">
              <img width="21" src="/assets/images/icons/user-green.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 positive">APROVAR</span>
            </li>
            <li nz-menu-item (click)="callToAction('BLOCK', data)" *ngIf="data?.status !== 'BLOCKED'">
              <img width="21" src="/assets/images/icons/block.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Bloquear</span>
            </li>
            <li nz-menu-item (click)="callToAction('UNBLOCK', data)" *ngIf="data?.status === 'BLOCKED'">
              <img width="21" src="/assets/images/icons/user-green.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 positive">Desbloquear</span>
            </li>
            <li nz-menu-item (click)="callToAction('REPROVE', data)" *ngIf="data?.status !== 'DECLINED'">
              <img width="21" src="/assets/images/icons/reprove.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Reprovar</span>
            </li>
            <li nz-menu-item (click)="callToAction('REPROCESS', data)">
              <img width="21" src="/assets/images/icons/reprocess.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1">Reprocessar</span>
            </li>
            <li nz-menu-item (click)="callToAction('BAN', data)" *ngIf="data?.status !== 'BANNED'">
              <img width="21" src="/assets/images/icons/ban.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1 negative">Bannir</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'SELLER_LIMITS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>
        {{ data?.amount || '-' | currency : 'R$' }}
      </td>
      <td nzEllipsis>
        {{ data?.perPeriod?.name || '-' }}
      </td>
      <td nzEllipsis>
        {{ data?.action?.name || '-' }}
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BANK-ACCOUNTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>
        {{ data?.type === 'CHECKING' ? 'Conta corrente' : 'Conta poupança' }}
      </td>
      <td nzEllipsis>
        {{ data?.bankName }}
      </td>
      <td nzEllipsis>{{ data?.agencyNumber }} - {{ data?.agencyCheckNumber }}</td>

      <td nzEllipsis>{{ data?.accountNumber }} - {{ data?.accountCheckNumber }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'EXTERNAL-CONSULTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>
        {{
          data?.cpfCnpj
            ? data.cpfCnpj.length === 11
              ? (data.cpfCnpj | mask : '000.000.000-00')
              : (data.cpfCnpj | mask : '00.000.000/0000-00')
            : '-'
        }}
      </td>
      <td nzEllipsis>
        {{ data?.datasets ? data.datasets.join(', ') : '-' }}
      </td>
      <td>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <span nz-icon class="default-icon" nzType="ellipsis" nzTheme="outline"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('DOWNLOAD', data)">Baixar consulta</li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BLACKLIST-B2B'">
    <tr *ngFor="let data of dynamicTable.data" (click)="showDetails(data)">
      <td nzEllipsis class="pointer-cursor">{{ data.name }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.cpf | mask : '000.000.000-00' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.cnpj | mask : '00.000.000/0000-00' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.email }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.phone?.replace('+55', '') | mask : '(00) 0 0000-0000' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.insertedAt | date : 'dd/MM/yyyy' }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BLACKLIST-B2C'">
    <tr *ngFor="let data of dynamicTable.data" (click)="showDetails(data)">
      <td nzEllipsis class="pointer-cursor">{{ data.name }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.cpf | mask : '000.000.000-00' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.email }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.phone?.replace('+55', '') | mask : '(00) 0 0000-0000' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.insertedAt | date : 'dd/MM/yyyy' }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BANNERS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.bannerType | bannerType }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <app-status-tag [type]="data.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.created_at | date : 'dd/MM/yyyy' }}</td>
      <td class="endBlock">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/images/icons/dots-vertical-primary.svg" alt="dots-icon" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="primary-color m-l-0-1">{{ data.active ? 'Inativar' : 'Ativar' }}</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/icons/red-trash.svg" alt="Ícone de lápis" />
              <span class="error-color m-l-0-1">Deletar</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BANNERS-SELECTION'" cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let data of dynamicTable.data" cdkDrag>
      <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
      <td nzEllipsis class="pointer-grab">{{ data.bannerType | bannerType }}</td>
      <td nzEllipsis class="pointer-grab">
        <app-status-tag [type]="data.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-grab">{{ data.created_at | date : 'dd/MM/yyyy' }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showPreview(data.bannerUrl)">
        <span nz-icon nzType="eye" nzTheme="outline"></span>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BANNER-GROUPING'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.display_at | bannerLocation }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        {{ data?.banners ? data.banners.length : 0 }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <app-status-tag [type]="data.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.created_at | date : 'dd/MM/yyyy' }}</td>
      <td class="endBlock">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/icons/dots-vertical.svg" alt="dots-icon" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="primary-color m-l-0-1">{{ data.active ? 'Inativar' : 'Ativar' }}</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/icons/red-trash.svg" alt="Ícone de lápis" />
              <span class="error-color m-l-0-1">Deletar</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PAYMENTS'">
    <tr *ngFor="let data of dynamicTable.data" (click)="showDetails(data)">
      <td nzEllipsis class="pointer-cursor">
        <app-status-tag class="m-l-1" [type]="data.status" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis class="pointer-cursor">
        {{ data.sellers?.company?.businessName || data?.sellers?.person?.name }}
      </td>
      <td nzEllipsis class="pointer-cursor">
        {{ data.customers?.name }}
      </td>
      <td nzEllipsis class="pointer-cursor">{{ data.method | paymentMethod }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.totalCents / 100 | currency : 'R$' || '-' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.insertedAt | date : 'dd/MM/yyyy' }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PAYMENT-ITEMS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor">{{ data.description }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.quantity }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.unitPriceCents / 100 | currency : 'R$' || '-' }}</td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'TOTAL-PAYMENTS'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor">{{ data.subtotal / 100 | currency : 'R$' || '-' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.discount / 100 | currency : 'R$' || '-' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.delivery / 100 | currency : 'R$' || '-' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.addition / 100 | currency : 'R$' || '-' }}</td>
      <td nzEllipsis class="pointer-cursor">{{ data.total / 100 | currency : 'R$' || '-' }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="tableType === 'ORDERS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data.id)">
        <td nzEllipsis>
          {{ data.insertedAt || '-' | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis>
          {{ data.items[0].description }}
        </td>
        <td nzEllipsis>
          {{
            data.customer?.cpf
              ? (data.customer?.cpf | mask : '000.000.000-00')
              : (data.customer?.cnpj | mask : '00.000.000/0000-00')
          }}
        </td>
        <td nzEllipsis>
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis>
          <app-status-tag [type]="data.status" gender="male"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'GEN_BOX'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.insertedAt || '-' | date : 'dd/MM/yyyy' }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data.id)">OPSP</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.seller?.store?.displayName || data.seller.company?.businessName || data.seller.person?.name }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data?.items ? data.items.length : 0 }}
        </td>
        <td class="pointer-cursor" (click)="showDetails(data.id)">
          <app-status-tag [type]="data.status" gender="male"></app-status-tag>
        </td>
        <td *ngIf="!data?.lockerOrders?.[0]?.id">
          <app-primary-button (click)="callToAction('GENERATE', data)"> Gerar pedido </app-primary-button>
        </td>
        <td nzEllipsis *ngIf="data?.lockerOrders?.[0]?.id" class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data?.lockerOrders?.[0]?.volumes?.[0]?.expressNumber }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'PRICE_TABLE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="showDetails(data)">
        <td nzEllipsis>{{ data.mallName }}</td>
        <td nzEllipsis>{{ data.updatedAt | date : 'dd/MM/yyyy' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ORDERS_ITEMS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.description }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.quantity }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.unitPriceCents / 100 | currency : 'R$' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ORDERS_VALUE'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.subtotalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.discountCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.shippingCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.additionCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'ORDERS_PAYMENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.method | paymentMethod }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.confirmedAt | customDate }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'HISTORY_PAYMENT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="callToAction('OPEN', data)">
        <td nzEllipsis class="pointer-cursor">
          {{ data.description }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.date | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.date | customDate : 'hh:mm' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'DATA_PAYMENT'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr class="pointer-cursor" (click)="callToAction('OPEN', data)">
        <td nzEllipsis class="pointer-cursor">
          {{ data.subtotal / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor">{{ data.fee }}%</td>
        <td nzEllipsis class="pointer-cursor">
          {{ data.discount / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor">{{ data.installments }}x</td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'CANCELLMENTS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          <app-status-tag [type]="data.status" />
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.payments?.id }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.amountCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
          {{ data.payments?.updatedAt | date : 'dd/MM/yyyy' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'WITHDRAWLS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis>
          <span>{{
            data.status === 'WAITING'
              ? 'Saque solicitado'
              : '' || data.status === 'APPROVED'
              ? 'Saque aprovado'
              : '' || data.status === 'DECLINED'
              ? 'Saque reprovado'
              : '' || data.status === 'EXECUTED'
              ? 'Saque realizado'
              : ''
          }}</span>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.insertedAt | customDate : 'DD/MM/YYYY HH:mm' }}
        </td>
        <td nzEllipsis>
          {{ data.amountCents / 100 | currency : 'R$' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'WITHDRAWLS-SYNAPSES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.wallets?.sellers?.company?.businessName || data.wallets?.sellers?.person?.name }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.amountCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          <app-status-tag [type]="data.status" gender="male"></app-status-tag>
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.insertedAt | customDate : 'DD/MM/YYYY HH:mm' }}
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'FEES'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.method | paymentMethod }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.installments }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.percent }} %</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.amountCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.insertedAt | date : 'dd/MM/yyyy' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          <app-status-tag [type]="data.active ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="tableType === 'SUBSCRIPTIONS'">
    <ng-container *ngFor="let data of dynamicTable.data">
      <tr>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.seller?.company?.businessName || data.seller?.person?.name || '-' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.customer?.name }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.totalCents / 100 | currency : 'R$' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.method | paymentMethod }}</td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          {{ data.insertedAt | date : 'dd/MM/yyyy HH:mm:ss' }}
        </td>
        <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">
          <app-status-tag [type]="data.status" gender="male"></app-status-tag>
        </td>
      </tr>
    </ng-container>
  </tbody>

  <tbody *ngIf="tableType === 'FIDELITY-COUPONS-SUMMARY'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data.id)">{{ data.name }}</td>
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.typeDiscount === 'PERCENT' ? 'Porcentagem' : 'Valor' }}
      </td>
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.expiration_date ? (data.expiration_date | date : 'dd/MM/yyyy') : 'Indefinida' }}
      </td>
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.redeemed_coupons ? data.redeemed_coupons.length : 0 }}
      </td>
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        <ng-container *ngIf="data.amount >= 0; else infinityAmount">
          {{ data.amount }}
        </ng-container>
        <ng-template #infinityAmount>
          <img class="wh-15" src="/assets/images/Infinity.svg" alt="Icone do infinito" />
        </ng-template>
      </td>
      <td>
        <nz-switch
          [formControl]="getCouponControl('active', data)"
          (ngModelChange)="changeCouponStatus(data)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
        <ng-template #checkedTemplate><span class="switch-check" nz-icon nzType="check"></span></ng-template>
        <ng-template #unCheckedTemplate><span class="switch-close" nz-icon nzType="close"></span></ng-template>
      </td>
      <td class="endBlock">
        <img
          class="pointer-cursor default-icon"
          src="/assets/images/icons/trash.svg"
          alt="Excluir cupom"
          (click)="callToAction('DELETE', data)"
        />
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'FIDELITY-COUPONS-ANALYSIS-REDMEED'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis>{{ data.customer ? data.customer.name : data.lead.name }}</td>
      <td nzEllipsis>
        {{ data.date_redeemed_seller | date : 'dd/MM/yyyy' : '-' }}
      </td>
      <td nzEllipsis>
        {{ data.buy_value_seller / 100 | currency : 'R$' }}
      </td>
      <td nzEllipsis>
        {{ data.coupon.code }}
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'FIDELITY-COUPONS-ANALYSIS-RESCUED'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor">{{ data.customer ? data.customer.name : data.lead.name }}</td>
      <td nzEllipsis class="pointer-cursor">
        {{ data.dateRescuedAt | date : 'dd/MM/yyyy' || '-' }}
      </td>
      <td nzEllipsis class="pointer-cursor">
        <app-primary-button (click)="callToAction('RESCUE', data)"> Resgatar </app-primary-button>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'FIDELITY-COUPONS-SYNAPSES'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.id }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.name }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.mall?.id | shoppingAcronym }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.seller?.business_name }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data.categories?.[0]?.name }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        {{ data.expiration_date ? (data.expiration_date | date : 'dd/MM/yyyy') : 'Indefinida' }}
      </td>
      <td class="pointer-cursor" (click)="showDetails(data)">{{ data.value / 100 | currency : 'R$' }}</td>
      <td class="pointer-cursor" (click)="showDetails(data)">
        <app-status-tag [type]="data.status" gender="male"></app-status-tag>
      </td>
      <td nzRight class="end">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/icons/dots-vertical.svg" alt="dots-icon" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('ASSOC', data)">
              <img src="/assets/icons/clone.svg" alt="Ícone de clonar" />
              <span class="primary-color m-l-0-1">Associar</span>
            </li>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="primary-color m-l-0-1">Editar</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/icons/red-trash.svg" alt="Ícone de uma lixeira vermelha" />
              <span class="error-color m-l-0-1">Deletar</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'INVENTORY'" class="inventoryTable">
    <tr class="itemTableRow" *ngFor="let data of dynamicTable.data">
      <td *ngIf="settingValue.checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
      <td nzEllipsis class="pointer-cursor">
        <div class="d-flex">
          <img
            *ngIf="data?.item?.imageUrls || data?.imageUrls"
            [src]="data?.item?.imageUrls || data?.imageUrls"
            class="m-r-2"
          />
          <div>
            <span class="d-block"> {{ data?.item?.name || data?.name || '-' }} </span>
            <span> {{ data?.item?.description || data?.description || '-' }} </span>
          </div>
        </div>
      </td>
      <td class="center">{{ data?.amountCents / 100 | currency }}</td>
      <td nzEllipsis class="pointer-cursor">
        <div class="d-flex">
          <form [formGroup]="formInventory">
            <input
              nz-input
              placeholder="0"
              [value]="data?.quantity || 0"
              (blur)="onQuantityChange(data)"
              (keypress)="onQuantityChange(data, $event)"
              [formControlName]="'amount' + data.id"
            />
          </form>
        </div>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="tableType === 'ITEM-PRICES'">
    <tr *ngFor="let data of dynamicTable.data">
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.itemVariantOptions.length > 0 ? data.itemVariantOptions[0]?.name : ' - ' }}
      </td>
      <td class="pointer-cursor" (click)="showDetails(data.id)">
        {{ data.price / 100 | currency : 'R$' }}
      </td>
      <td>
        <nz-switch
          [formControl]="getItemUnitControl('active', data)"
          (ngModelChange)="changeItemPriceStatus(data)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
        <ng-template #checkedTemplate><span class="switch-check" nz-icon nzType="check"></span></ng-template>
        <ng-template #unCheckedTemplate><span class="switch-close" nz-icon nzType="close"></span></ng-template>
      </td>
      <td>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/images/icons/dots-vertical.svg" alt="Ícone de três pontos na vertical" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul class="option-menu" nz-menu>
            <li nz-menu-item (click)="callToAction('DUPLICATE', data)">
              <img width="21" src="/assets/images/icons/clone.svg" alt="Ícone de duplicar" />
              <span style="margin-left: 9px" class="colorDefault">Duplicar</span>
            </li>
            <li nz-menu-item (click)="callToAction('STORE', data)">
              <img width="21" src="/assets/images/icons/receipt-alt.svg" alt="Ícone de comprovante" />
              <span class="m-l-0-1">Copiar link do catálogo</span>
            </li>
            <li nz-menu-item (click)="callToAction('COPY', data)" [nzDisabled]="seller.status !== 'ACTIVE'">
              <img width="21" src="/assets/images/icons/receipt-alt-two.svg" alt="Ícone de comprovante" />
              <span style="margin-left: 10px" class="colorDefault">Copiar link de checkout</span>
            </li>
            <li nz-menu-item (click)="callToAction('UPDATE', data)">
              <img width="21" src="/assets/images/icons/check.svg" alt="Ícone de sucesso" />
              <span style="margin-left: 10px" class="colorDefault">Ativar/desativar no catálogo</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'CAMPAIGNS_LIST_SYNAPSES'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        {{ data.title || getSegmentationNames(data.segmentations, null) }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <div class="comunication-tag-container">
          <div class="comunication-tag" *ngIf="data.hasSms">
            <span>SMS</span>
          </div>
          <div class="comunication-tag" *ngIf="data.hasEmail">
            <span>E-mail</span>
          </div>
          <div class="comunication-tag" *ngIf="data.hasWhatsapp">
            <span>Whatsapp</span>
          </div>
          <span *ngIf="!data.hasEmail && !data.hasSms && !data.hasWhatsapp"> Nenhum canal selecionado </span>
        </div>
      </td>
      <td class="pointer-cursor" (click)="showDetails(data)">
        <span class="m-l-2">{{ getTotalUniqueContacts(data.segmentations) }}</span>
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <span class="m-l-1"> {{ data.sendDate | customDate }} </span>
      </td>
      <td class="pointer-cursor" (click)="showDetails(data)">
        {{ data.endDate | customDate }}
      </td>
      <td>
        <app-status-tag [type]="data.status" gender="male"></app-status-tag>
      </td>
      <td class="end">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/images/icons/dots-vertical.svg" alt="dots-icon" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('DUPLICATE', data)">
              <img width="21" src="/assets/images/icons/clone.svg" alt="Ícone de duplicar" />
              Duplicar
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/images/icons/trash.svg" alt="Ícone de lixeira." />
              Excluir
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'CAMPAIGNS_LIST'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        {{ data.title || getSegmentationNames(data.segmentations, null) }}
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <div class="comunication-tag-container">
          <div class="comunication-tag" *ngIf="data.hasSms">
            <span>SMS</span>
          </div>
          <div class="comunication-tag" *ngIf="data.hasEmail">
            <span>E-mail</span>
          </div>
          <div class="comunication-tag" *ngIf="data.hasWhatsapp">
            <span>Whatsapp</span>
          </div>
          <span *ngIf="!data.hasEmail && !data.hasSms && !data.hasWhatsapp"> Nenhum canal selecionado </span>
        </div>
      </td>
      <td class="pointer-cursor" (click)="showDetails(data)">
        <span class="m-l-2">{{ getTotalUniqueContacts(data.segmentations) }}</span>
      </td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">
        <span class="m-l-1"> {{ data.sendDate | customDate }} </span>
      </td>
      <td class="pointer-cursor" (click)="showDetails(data)">
        {{ data.endDate | customDate }}
      </td>
      <td>
        <app-status-tag [type]="data.status" gender="male"></app-status-tag>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'MALLS'">
    <tr *ngFor="let data of dynamicTable.data" class="pointer-cursor">
      <td (click)="showDetails(data)">
        <app-status-tag [type]="data?.mall?.status" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data?.mall?.name }}
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data?.mall?.cnpj | mask : '00.000.000/0000-00' }}
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data.updatedAt | date : 'dd/MM/yyyy' || '-' }}
      </td>
      <td>
        <div class="centerBlock" *ngIf="data.loading">
          <nz-spin class="spinner centerBlock" nzSize="small"></nz-spin>
        </div>

        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" *ngIf="!data.loading">
          <img src="/assets/images/icons/dots-vertical-primary.svg" alt="Ícone de três pontos na vertical" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul class="option-menu" nz-menu>
            <li nz-menu-item (click)="callToAction('PRICES', data)">
              <img src="/assets/images/icons/edit-all.svg" alt="Ícone de clonar" />
              <span class="m-l-0-1">Visualizar tabela de preços</span>
            </li>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="m-l-0-1">Editar garagem</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/images/icons/trash.svg" alt="Ícone de uma lixeira" />
              <span class="m-l-0-1">Excluir garagem</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'PARKING-CAMPAIGN'">
    <tr *ngFor="let data of dynamicTable.data" class="pointer-cursor">
      <td (click)="showDetails(data)">
        <app-status-tag [type]="data?.isActive ? 'ACTIVE' : 'INACTIVE'" gender="male"></app-status-tag>
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data?.name || '-' }}
      </td>
      <td nzEllipsis (click)="showDetails(data)">
        {{ data.discountType === 'PERCENT' ? 'Porcentagem' : 'Valor' }}
      </td>

      <td nzEllipsis (click)="showDetails(data)" *ngIf="data.discountType === 'VALUE'">
        {{ data.discountValue | currency : 'R$' }}
      </td>

      <td nzEllipsis (click)="showDetails(data)" *ngIf="data.discountType === 'PERCENT'">
        <nz-tag [nzColor]="'blue'">{{ data.discountValue }}%</nz-tag>
      </td>
      <td nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <div class="centerBlock" *ngIf="data.loading">
          <nz-spin class="spinner centerBlock" nzSize="small"></nz-spin>
        </div>

        <a *ngIf="!data.loading">
          <img src="/assets/images/icons/dots-vertical-primary.svg" alt="Ícone de três pontos na vertical" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul class="option-menu" nz-menu>
            <li nz-menu-item (click)="callToAction('DETAILS', data)">
              <img src="/assets/images/icons/edit-all.svg" alt="Ícone de clonar" />
              <span class="m-l-0-1">Ir para detalhes</span>
            </li>
            <li nz-menu-item (click)="callToAction('EDIT', data)">
              <img src="/assets/icons/primary-edit.svg" alt="Ícone de lápis" />
              <span class="m-l-0-1">Editar Promoção</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/images/icons/trash.svg" alt="Ícone de uma lixeira" />
              <span class="m-l-0-1">Excluir Promoção</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>

  <tbody *ngIf="tableType === 'BOX_OFFICE_TICKET'">
    <tr *ngFor="let data of dynamicTable.data">
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.participant }}</td>
      <td nzEllipsis class="pointer-cursor" (click)="showDetails(data)">{{ data?.customer?.cpf | hidePartialCpf }}</td>
      <td class="endBlock">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <img src="/assets/icons/dots-vertical.svg" alt="dots-icon" />
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="callToAction('DETAILS', data)">
              <img src="/assets/images/icons/edit-all.svg" alt="Ícone de clonar" />
              <span class="primary-color m-l-0-1">Visualizar detalhes do participante</span>
            </li>
            <li nz-menu-item (click)="callToAction('DELETE', data)">
              <img src="/assets/images/icons/trash.svg" alt="Ícone de uma lixeira" />
              <span class="error-color m-l-0-1">Excluir participante</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
  <ng-template #itemFooter>
    <tfoot class="w-100 d-flex">
      <tr class="w-100 d-table">
        <td class="w-60"><strong>Total</strong></td>
        <td class="w-20">
          <strong>{{ footerData.countQuantity }}</strong>
        </td>
        <td class="w-20">
          <strong>{{ footerData.totalPayments / 100 | currency : 'R$' }}</strong>
        </td>
      </tr>
    </tfoot>
  </ng-template>
  <ng-template #paymentFooter>
    <tfoot class="w-100 d-flex">
      <tr class="w-100 d-table">
        <td class="w-16"><strong>Total</strong></td>
        <td class="w-16">
          <strong>{{ footerData.countPayments }}</strong>
        </td>
        <td class="w-16">
          <strong>{{ footerData.countRefunds }}</strong>
        </td>
        <td class="w-16">
          <strong>{{ footerData.totalPayments | currency : 'R$' }}</strong>
        </td>
        <td class="w-16">
          <strong>{{ footerData.totalRefunds | currency : 'R$' }}</strong>
        </td>
        <td class="w-16">
          <strong>{{ footerData.totalAmount | currency : 'R$' }}</strong>
        </td>
      </tr>
    </tfoot>
  </ng-template>
  <ng-template #editableTableFooter>
    <div class="editable-table">
      <tfoot class="w-100 d-flex">
        <span class="m-l-1"> Valor Total </span>
        <span class="m-r-1"> {{ footerData?.totalAmount / 100 | currency : 'R$' }} </span>
      </tfoot>
    </div>
  </ng-template>
  <ng-template #receiveFooter>
    <tfoot (click)="callToAction('SHOW_FOOTER')" class="w-100 d-flex pointer-cursor">
      <tr class="w-100 d-table">
        <td class="w-40"></td>
        <td class="w-15">{{ footerData?.totalLiquid | currency : 'R$' }}</td>
        <td class="w-15">{{ footerData?.totalAmount | currency : 'R$' }}</td>
        <td class="w-30"></td>
      </tr>
    </tfoot>

    <tfoot class="w-100 d-flex">
      <tr class="w-100 d-table">
        <td class="w-70"></td>
        <td class="w-15">Saldo em transferência</td>
        <td class="w-15">{{ footerData?.totalToReceive | currency : 'R$' }}</td>
      </tr>
    </tfoot>
  </ng-template>
  <ng-template #walletFooter>
    <tfoot (click)="callToAction('SHOW_FOOTER')" class="w-100 d-flex pointer-cursor">
      <tr class="w-100 d-table">
        <td class="w-40"></td>
        <td class="w-15">{{ footerData.totalCredit | currency : 'R$' }}</td>
        <td class="w-15">{{ footerData.totalDebit | currency : 'R$' }}</td>
        <td class="w-30"></td>
      </tr>
    </tfoot>

    <tfoot class="w-100 d-flex">
      <tr class="w-100 d-table">
        <td class="w-70"></td>
        <td class="w-15">Saldo total</td>
        <td class="w-15">{{ footerData.totalBalance | currency : 'R$' }}</td>
      </tr>
    </tfoot>
  </ng-template>
  <ng-template #balanceFooter>
    <div class="balance-table">
      <tfoot class="w-100">
        <div class="between w-100">
          <span class="m-l-1 credit"> Total de entradas </span>
          <span class="m-r-1 credit"> {{ footerData?.totalCredit / 100 | currency : 'R$' }} </span>
        </div>

        <hr />

        <div class="between w-100">
          <span class="m-l-1 debit"> Total de saídas</span>
          <span class="m-r-1 debit"> {{ footerData?.totalDebit / 100 | currency : 'R$' }} </span>
        </div>
      </tfoot>
    </div>
  </ng-template>
</nz-table>
