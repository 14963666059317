<section id="confim-code">
  <div class="sent-msg">
    <p>
      Insira abaixo o código que foi enviado para o número <br />
      <span> {{ phoneNumber.replace('+55', '') | mask : '(00) 0 0000-0000' }} </span>
    </p>
  </div>
  <div id="phoneCode">
    <form nz-form [formGroup]="formConfirmPhone" (keydown.enter)="keyDownFunction($event)">
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code1"
            type="tel"
            id="code1"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 1)"
            (paste)="pasteNumeric($event)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code2"
            type="tel"
            id="code2"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 2)"
            (keyup)="onPressDeleteKey($event, 1)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code3"
            type="tel"
            id="code3"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 3)"
            (keyup)="onPressDeleteKey($event, 2)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code4"
            type="tel"
            id="code4"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 4)"
            (keyup)="onPressDeleteKey($event, 3)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code5"
            type="tel"
            id="code5"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 5)"
            (keyup)="onPressDeleteKey($event, 4)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            nz-input
            formControlName="code6"
            type="tel"
            id="code6"
            autocomplete="off"
            mask="0"
            (keyup)="onPressDeleteKey($event, 5)"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  @if (!allowResend) {
  <div class="pointer-cursor disabled-resend-code" (click)="resendCode()" [class.disabled-button]="!allowResend">
    <span class="resend-code-text">Enviar código novamente &nbsp;{{ countdown }} (seg.)</span>
  </div>
  }@else {
  <div class="pointer-cursor resend-code" (click)="resendCode()" [class.disabled-button]="!allowResend">
    <span class="resend-code-text">Enviar código novamente</span>
  </div>
  }

  <div class="footer">
    <app-secondary-button class="start" (click)="closeModal()">Voltar</app-secondary-button>
    <app-primary-button
      class="end"
      [loading]="loading"
      [disabled]="formConfirmPhone.invalid || loading"
      (click)="validatePhoneNumberCode()"
    >
      Entrar
    </app-primary-button>
  </div>
</section>
