<section>
  <nz-collapse (click)="collapseClick()" [ngClass]="isCollapseClicked ? 'collapse collapse__active' : 'collapse'">
    <nz-collapse-panel #p nzHeader="Compartilhar" [nzExtra]="extraTpl" [nzExpandedIcon]="expandedIcon">
      <div class="collapse-content" (click)="$event.stopPropagation()">
        <div class="icons">
          <div
            *ngFor="let method of methods; trackBy: trackByFn"
            (click)="sendToPlatform(method.type); $event.stopPropagation()"
            class="pointer-cursor"
          >
            <img [src]="method.imageUrl" alt="Imagem dos métodos" />
          </div>
        </div>

        <div class="button">
          <app-secondary-button
            iconImg="/assets/icons/clone-dark-purple.svg"
            alt="Ícone de copiar"
            type="DARK-PURPLE"
            [block]="isMobile"
            class="w-100 copy-btn"
            (click)="copyContent(); $event.stopPropagation()"
            [disabled]="isCopied"
          >
            {{ isCopied ? 'Link copiado ' : 'Copiar' }}
          </app-secondary-button>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #expandedIcon let-active>
    <img class="m-r-0-1" src="/assets/img/share.svg" alt="Botão para compartilhar" />
  </ng-template>

  <ng-template #extraTpl>
    <span nz-icon nzType="down" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 0 : -90"></span>
  </ng-template>
</section>
