import { Component, Input } from '@angular/core';
import { Message } from '../../../utils/message';
import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { Subject, takeUntil } from 'rxjs';
import { LeadModel } from '../../../models/lead/lead.model';
import { Router } from '@angular/router';
import { error } from 'console';

@Component({
  selector: 'gen-monorepo-modal-brand-details',
  templateUrl: './modal-brand-details.component.html',
  styleUrl: './modal-brand-details.component.scss',
})
export class ModalBrandDetailsComponent {
  @Input() public id: string = '';

  public seller: SellerModel = new SellerModel();
  public lead: LeadModel = new LeadModel();

  public loading: boolean = true;

  private destroy$ = new Subject();

  public brandSeller: any;

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private $notification: StateManagementService,
    private readonly router: Router,
    public modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.getLead();
  }

  private getLead(): void {
    this.$notification.leads.subscribe((lead) => {
      this.lead = lead;
      this.getSeller(lead?.id);
    });
  }

  private takeBrand(sellerId: string): void {
    this.$lead.takeABrandToSeller(sellerId).subscribe({
      next: (res) => {
        this.brandSeller = res?.data?.storeBySellerId;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  private getSeller(leadId: string): void {
    this.$lead
      .getCouponsBySellerId(this.id, leadId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.sellerToCoupon) {
            this.seller = JSON.parse(JSON.stringify(res.data.sellerToCoupon));
          }

          this.takeBrand(this.seller?.id);
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public goToStore(): void {
    this.modalRef.close();
    this.router.navigate([`/stores/${this.seller.id}`]);
  }
}
